//import React from 'react';
//import {render} from 'react-dom';
import * as ReactDOMClient from "react-dom/client";
//import {createRoot} from 'react-dom/client';
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
//import Reboot from '@material/material/core/Reboot';
import { ThemeProvider, createTheme } from "@mui/material/styles";
//import {purple,green,grey,pink,yellow} from '@material/material/core/colors';
import LoginPage from "./components/startup/LoginPage";
import HomePage from "./components/startup/HomePage";
import { bizpost } from "./ajax";
import "./index.css";

const theme = createTheme({
  palette: {
    primary: {
      light: '#843e9c',
      main: '#540d6d',
      dark: '#280041',
      contrastText: "#ffffff",
    },
    secondary: {
      light: '#ffdb99',
      main: '#e1a96a',
      dark: '#ad7a3d',
      contrastText: "#000000",
    },
    text: { hint: "rgba(0, 0, 0, 0.38)" },
  },

  // palette0: {
  //   primary: {
  //     light: "#00AEEF",
  //     main: "#1DACD6",
  //     dark: "#24419B",
  //     contrastText: "#ffffff",
  //   },
  //   secondary: {
  //     light: "#c3DA4B",
  //     main: "#BDDA57",
  //     dark: "#C3DA4B",
  //     contrastText: "#000000",
  //   },
  //   text: { hint: "rgba(0, 0, 0, 0.38)" },
  // },

  // palette: {
  //   mode: 'light',
  //   primary: {
  //     main: '#100f4d',
  //   },
  //   secondary: {
  //     main: '#eb0c0d',
  //     contrastText: '#f4f4f4',
  //   },
  // },
});

const start = async () => {
  const logincontext = true;
  try {
    const json = { s: "security", a: "basicdata" };
    //startLoading();
    const response = await bizpost(json);
    console.log("login response ", response);
    var page;
    if (!response.bd) {
      page = (
        <LoginPage
          home={HomePage}
          apptheme={theme}
          logincontext={logincontext}
        />
      );
    } else {
      page = <HomePage udata={response} />;
    }
  } catch (error) {
    page = (
      <LoginPage home={HomePage} apptheme={theme} logincontext={logincontext} />
    );
  } finally {
    //stopLoading();
    const container = document.getElementById("app");

    // Create a root.
    if (container !== null) {
      const root = ReactDOMClient.createRoot(container);
      root.render(<ThemeProvider theme={theme}>{page}</ThemeProvider>);
    }

    //render(<MuiThemeProvider theme={theme}>{page}</MuiThemeProvider>, document.getElementById('app'));
  }
};

start();
