import React, { Component } from "react";
import PropTypes from "prop-types";
import { Theme } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import { pink } from "@mui/material/colors";
// import {
//   Tooltip,
//   XAxis,
//   YAxis,
//   LineChart,
//   Line,
//   ResponsiveContainer,
// } from "recharts";
import moment from "moment";
import FilterListIcon from "@mui/icons-material/FilterList";
import DateDialog from "../dialogs/DateDialog";
import IconButton from "@mui/material/IconButton";
import { Tab, Tabs } from "@mui/material";

const styles = (theme: Theme): any => ({
  paper: {
    backgroundColor: "white",
    // minHeight: 400,
  },
  div: {
    //height: 95,
    padding: "5px 15px 0 15px",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    fontSize: 24,
    fontWeight: theme.typography.fontWeightLight,
    color: "white",
    backgroundColor: theme.palette.secondary.main,
    padding: 10,
    alignItems: "center",
    justifyContent: "space-between",
  },
  transaction: {
    color: "#8884d8",
  },
  amount: {
    color: "#4d8888",
  },
  custom_tooltip: {
    lineHeight: 0.2,
    backgroundColor: "white",
    padding: 5,
  },
  filterButton: {
    height: "20px",
    gap: 10,
  },
  nodata: {
    display: "flex",
    justifyContent: "center",
  },
});

class Header extends Component<any, any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props: any) {
    super(props);
    this.state = {
      count: 0,
      tabid: 0,
      mode: "tot", //'amt'
      lwopen: false,
    };
  }

  // componentWillReceiveProps(nextProps) {
  //   if (this.props.page !== nextProps.page) {
  //       this.setState({page: nextProps.page});
  //   }
  // }

  ttFormatTime = (stamp: any) => {
    return moment.unix(stamp).format("DD MMM");
  };

  customTooltip = ({ active, payload, label }: any) => {
    const { classes } = this.props;
    if (payload === null || payload === undefined) return null;

    if (active) {
      return (
        <div className={classes.custom_tooltip}>
          <p>{this.ttFormatTime(label)}</p>
          <p
            className={classes.transaction}
          >{`${payload[0].name} : ${payload[0].value}`}</p>
          <p className={classes.amount}>{`${payload[1].name} : ${
            "GHS " + payload[1].value
          }`}</p>
        </div>
      );
    }
    return null;
  };

  onTabChange = (event: any, value: number) => {
    this.setState({ tabid: value });
  };

  onClick = (meter: any) => {
    this.setState({ lwopen: true });
  };

  onClose() {
    this.setState({ lwopen: false });
  }

  render() {
    const {
      data,
      title = "Filter",
      classes,
      onFilter,
      start,
      end,
    } = this.props;
    const { tabid, onTabChange } = this.props;
    const { mode, lwopen } = this.state;

    return (
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <Tabs
            orientation="horizontal"
            onChange={onTabChange}
            TabIndicatorProps={{ className: "hidden sm:block" }}
            classes={{
              flexContainer: "flex-wrap sm:flex-nowrap",
            }}
          >
            <Tab label={"Ordering"} disabled={tabid === 0} />
            <Tab label={"Production"} disabled={tabid === 1} />
            <Tab label={"Distribution"} disabled={tabid === 2} />
            <Tab label={"Installation"} disabled={tabid === 3} />
            <Tab label={"Disposal"} disabled={tabid === 4} />
            <Tab label={"Maintenance"} disabled={tabid === 5} />
          </Tabs>

          <IconButton
            className={classes.filterButton}
            aria-haspopup={true}
            onClick={this.onClick}
            color="inherit"
          >
            {title}
            <FilterListIcon />
          </IconButton>
        </div>
        <div>
          <DateDialog
            open={lwopen}
            onClose={this.onClose.bind(this)}
            onFilter={onFilter}
          />
        </div>
      </Paper>
    );
  }
}

Header.propTypes = {
  data: PropTypes.array,
  onFilter: PropTypes.func
};

export default withStyles(styles, { withTheme: true })(Header);
