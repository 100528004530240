import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import {bizpost} from '../../ajax';
//import combos from '../../core/combo';
import {combos} from '../../core';

const styles = (theme:Theme):any => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  main: {
    width: '100%'
  },
  menu: {
    width: 200,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
  group: {
    margin: theme.spacing(1, 0),
    display: 'flex',
  },
});

class FAutoComplete extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  combo:any;
  constructor(props:any) {
    super(props);
    //this.combo = React.createRef(); //TODO: only if childs or parents
    const {config} = this.props;
    const cbs = combos(config.t,config.s)||{};
    let civ = props.value;
    let rec = this.formatOption(civ,cbs);

    console.log('FAComplete.constructor.civ ',civ);
    console.log('FAComplete.constructor.rec ',rec);

    this.state = {
      cbs: cbs||{},
      next: true,
      records:[rec], //{nam:'Select',value:null}
      record:rec||{},
      count:0,
      value: civ,
      civ,
      reset: 0, //0:normal/default,1:changed by onChange,2:updated list after onchange
    };
  }

  //TODO: limit to active parents only. check parent details
  // static getDerivedStateFromProps(nextProps, prevState){
  //   if(nextProps.cvalue!==prevState.value){
  //     return { value: nextProps.cvalue};
  //   }
  //   else return null;
  // }

  componentDidUpdate(prevProps:any, prevState:any) {
    if(prevProps.cvalue!==this.props.cvalue){
      //Perform some operation here
      //this.setState({value: this.props.value});
      //this.classMethod();
    }
    //TODO: prune bad parents
    const {id,cuparams,parents} = this.props;
    //console.log('cuparams:',id,cuparams);
    //console.log('parents:',id,parents);
    //only if it has parents and combo updates set 
    if(cuparams  && parents && prevProps.cuparams !== cuparams){
      const uparams = this.findUpdate(cuparams,parents);
      console.log('uparams:',uparams);
      this.refresh(uparams);
    }
  }

  componentDidMount(){
    this.refresh();
    // const {config} = this.props;
    // const cbs = combos(config.t,config.s)||{};
    // this.setState({cbs:cbs});
    // if(config.t==='lcs') this.locrefresh(cbs);
    // else this.remrefresh(cbs);
  }

  findUpdate(params:any,parents:any){
    const filtered = Object.keys(params)
      .filter((key:any) => parents.includes(key))
      .reduce((obj:any, key:any) => {
        obj[key] = params[key];
        return obj;
      }, {});
    return filtered;
  }

  refresh(uparams?:any){
    const {config} = this.props;
    const cbs = combos(config.t,config.s)||{};
    this.setState({cbs:cbs});
    if(config.t==='lcs') this.locrefresh(cbs);
    else if(config.t==='bcs') this.remrefresh(cbs,uparams);
  }

  locrefresh = (cbs:any) => {
    let uparams = true;
    let err = !cbs.sd;
    if(err){
      //TODO: show message here
      console.log("lcs data not available");
      this.setState({records:[],count:0});
    }
    else{
      let {records,record,value,reset} = this.state;
      //const {vf,df} = cbs;
      const sd = cbs.sd.reduce( (acc:any[],rd:any) => { acc.push({rid:rd[0],nam:rd[1]});return acc;}, []);
      const rc = sd.length;
      const sc = sd.length;
      //const value = rc>0?this.format(sd[0],cbs):this.state.value;
      //this.setState({records:sd,count:rc,value});
      if(sc>0){
        //reset(refresh combo) if combo value has changed (oldreset==false by onChange) and new update params in refresh (uparams is set, true))
        //if already reset(2) and not changed, goto 0 (default) and dont trigger reset. if changed(1) and updating, trigger reset(2), else keep as is.
        const newreset = (reset===2)?0:(reset===1 && !!uparams)?2:reset;
        const newvalue = (reset===2)?value:(reset===1 && !!uparams)?null:value;

        const [newrecord,newrecords] = this.formatOptions(sd,cbs,newvalue);
        this.setState({records:newrecords,record:newrecord,count:+rc,reset:newreset,value:newvalue});
      }
    }
      
  }

  remrefresh = async(cbs:any,uparams:any) =>{
    if(!cbs.sv) return;
    try{
      var {records,record,value,reset} = this.state;
      const {service,config,filters} = this.props;
      let s = cbs.sv||config.s||service,
          a = config.a||'combo',
          params = {s:s,a:a,...filters,...(uparams||{})};
      //console.log('autocomplete.params:',params);
      const response = await bizpost(params);
      //console.log('autocomplete.response:',response);
      const {sd,rc} = response;
      const sc = (sd||[]).length;
      //console.log('autocomplete.sc:',sc);
      if(sc>0){
        //reset(refresh combo) if combo value has changed (oldreset==false by onChange) and new update params in refresh (uparams is set, true))
        //if already reset(2) and not changed, goto 0 (default) and dont trigger reset. if changed(1) and updating, trigger reset(2), else keep as is.
        const newreset = (reset===2)?0:(reset===1 && !!uparams)?2:reset;
        const newvalue = (reset===2)?value:(reset===1 && !!uparams)?null:value;

        const [newrecord,newrecords] = this.formatOptions(sd,cbs,newvalue);
        //const newrecord = copt; //(reset===2)?record:(reset===1&&!!uparams)?{}:record ; //{...record,nam:}
        // console.log('FA.remrefresh.value', value);
        // console.log('FA.remrefresh.newvalue', newvalue);
        // console.log('FA.remrefresh.reset', newreset);
        // console.log('FA.remrefresh.record', record);
        // console.log('FA.remrefresh.newrecord', newrecord);
        // console.log('FA.remrefresh.records', records);
        // console.log('FA.remrefresh.newrecords', newrecords);
        // console.log('FA.remrefresh.sd', sd);
        // console.log('FA.remrefresh.rc', rc);
        // console.log('FA.remrefresh.sc', sc);
        this.setState({records:newrecords,record:newrecord,count:+rc,reset:newreset,value:newvalue});

        // let nreset,oreset,nvalue,ovalue;
        // //no uparams
        // if(reset===2){
        //   nreset = 0;
        //   nvalue = value;
        //   this.setState({records:fopts,count:+rc,reset:0,value});
        //   //this.setState({records:fopts,count:+rc,reset:newreset,value:newvalue});
        // }
        // else if()
      }
      else{
        //reset combo? and selected record?
      }
    }
    catch(error){
      console.log('combo.error:',error);
      this.setState({records:[],count:0});
      if(this.props.onError) this.props.onError(error);
    }
    finally{
      //this.stopLoading();
    }
  }

  formatOption = (value:any,cbs:any) => {
    const {vf,df} = cbs;
    let option:any = {[vf]:value,[df]:value};
    option.value = this.format(option,cbs);
    return option;
  }

  formatOptions = (options:any,cbs:any,value:any) => {
    let cuoption = {value};
    const foptions = options.map((option:any) => {
      option.value = this.format(option,cbs);
      if(option.value === value) cuoption = option;
      return option;
    });
    return [cuoption,foptions];
  }

  format = (item:any,cbs:any) => {
    const {vFormat} = this.props;
    let vftype = typeof vFormat;
    if(vftype === 'function') return vFormat(item,cbs);
    const {vf,df} = cbs;
    let id = /\d+/.test(item[vf])?+item[vf]:item[vf];
    if(vftype === 'string') {
      if(vFormat === 'status') return ['disabled','active'][id]||id;
      if(vFormat === 'gender') return ['F','M'][id]||id;
      if(vFormat === 'truthy') return ['N','Y'][id]||id;
      if(vFormat === 'label') return item[df].replace(/\s/g,'_');
    }
    return item[vf];
  }

  onChange = (event:any,val:any,reason:any) => { 
    const {records} = this.state;
    const { options, parents,childs,onChange } = this.props;
    const {multiple} = (options||{});
    const value = val&&((typeof val === 'object')?val.value:val);
    const record = this.getValue(records,value);
    //TODO: Consider creating a separate Component for Multiselect
    if (typeof onChange === 'function') multiple?onChange(value,multiple,reason):onChange(value,val,childs,reason);
    this.setState({record,value,reset:1});
    //if(childs && typeof this.props.resetChildren === 'function') this.props.resetChildren(childs);
  };

  getOptionLabel = (name:any) => (option:any) => option[name]||'';
  // getOptionLabel = (name:any) => (option:any) => {
  //   console.log('getOptionLabel: ',name,', => ',option);
  //   return option[name]||'';
  // };

  getValue = (records:any,value:any) => records.filter((record:any) => record.rid === value)[0];

  setCombo = (c:any) =>{
    this.combo = c;
  }

  render() {
    //const {service,action,id,label,required,autoFocus,options,onChange,value,divider,classes } = this.props;
    const {id,label,required,options,filterOptions,iprops,divider,classes,theme } = this.props;
    const { records, record, cbs, value, civ,reset } = this.state;
    //console.log('FA.render.civ ',civ);
    //console.log('FA.render.value ',value);
    const opfun = (typeof filterOptions === 'function');
    //console.log('FA.render.options ',options);
    const {ioptions,...ooptions} = (options||{});
    //console.log('fa.ioptions: ',ioptions);
    const {InputProps:inprops,...extraoptions} = ioptions||{};
    //console.log('fa.InputProps: ',inprops);
    //console.log('fa.extraoptions: ',extraoptions);
    const defaultValue = this.getValue(records,value);
    const key = (reset===2)?'ukey':'nkey';
    //const {multiple} = ooptions;
    //console.log('FA.render.records ',records);
    //console.log('FA.render.record ',record);
    //console.log('FA.render.value.2 ',value);
    //console.log('FA.render.defaultValue',defaultValue,reset,key);
    const df = cbs.df || 'nam';
    const faoptions = {
      id,
      //key, //updating key will force the component to reload default, a.k.a refresh old input value. This is not working for me
      ref: this.combo, //TODO: only if childs or parents
      options:records,
      getOptionLabel:this.getOptionLabel(df),
      //defaultValue: defaultValue,
      value: record,
      style:{ width: '100%' },
      className:classes.main,
      multiple:false,
      clearOnEscape:true,
      autoSelect:false,
      filterSelectedOptions:true,
      loading:true,
      onChange:this.onChange,
      ...(ooptions||{})
    }
    //if reset triggered, set key to trigger refresh
    //if(reset===2) faoptions.key = id;
    if(opfun) faoptions.filterOptions = filterOptions;
    
    return (
      <div className={classes.root}>
        <Autocomplete
          {...faoptions}
          renderInput={(params) => {
            // const mergedprops = {
            //   ...(inprops||{}),
            //   ...(params.InputProps||{})
            // };
            //params.InputProps = mergedprops;
            //console.log('fa.params: ',params);
            //console.log('fa.mergedprops: ',mergedprops);
            return (
            <TextField
              {...params}
              label={label}
              required={required}
              margin='dense'
              className={classes.textField}
              InputProps={{...(params.InputProps||{}),...(inprops||{})
                // endAdornment: (
                //   <React.Fragment>
                //     {params.InputProps.endAdornment}
                //   </React.Fragment>
                // )
              }}
              InputLabelProps={{shrink: true , autoComplete:''}}
              inputProps={{
                ...(params.inputProps||{}),
              }}
              autoComplete = 'new-password' // disable autocomplete and autofill
              // variant="filled" 
              {...(extraoptions||{})}
            />
            );
          }}
        />
        {divider && <Divider />}
      </div>
    );
  }

}

FAutoComplete.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FAutoComplete);
