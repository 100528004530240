import React, { Component } from "react";
import type { Theme } from "@mui/material";
import { withStyles } from "@mui/styles";
import {
  grey,
} from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import PageBase from "../site/PageBase";
import Header from "./Header";
import Daily from "./libs/Daily";
import Recent from "./libs/Recent";
import Monthly from "./libs/Monthly";
import { bizpost } from "../../ajax";
import { ProgressDialog } from "../dialogs";
import Production from "./Production";
// import Rating from "./Rating";
// import Performance from "./Performance";
// import Compliance from "./Compliance";
// import Activity from "./Activity";
import Installation from "./Installation";
import Disposal from "./Disposal";
import Maintenance from "./Maintenance";
import Ordering from "./Ordering";
import Distribution from "./libs/Distribution";

const styles = (theme: Theme): any => ({
  root: {
    flexGrow: 1,
  },
  navigation: {
    fontSize: 15,
    fontWeight: 300,
    color: grey["600"],
    paddingBottom: 15,
    display: "block",
  },
  title: {
    fontSize: 24,
    fontWeight: 300,
    marginBottom: 20,
    color: "white",
  },
  paper: {
    padding: 30,
  },
  clear: {
    clear: "both",
  },
});

const boards = [Ordering, Production, Distribution, Installation, Disposal, Maintenance];

class Dashboard extends Component<any, any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props: any) {
    super(props);
    const {dd} = this.props.userData || {};
    const {meta} = dd||{};
    this.state = {
      dd: this.props.userData.dd,
      meta: meta||[],
      ping: null,
      timer: 20000, //300000,
      count: 0,
      loading: false,
      isFiltered: false,
      tabid: 0,
    };
  }

  startLoading = () => {
    this.setState({ loading: true });
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };
  componentDidMount() {
    const { meta:smeta, timer } = this.state;
    const addmeta = (Array(smeta).length === 0)? 1 : 0;
    var ping = setInterval(async () => {
      try {
        const baseparams = { s: "security", a: "dashdata", met: addmeta };
        const response = await bizpost(baseparams);
        console.log('dd resp',response);
        //-------------------------------------
        // const {dd:rdd} = response||{};
        // const {meta:rmeta} = rdd;
        // const meta = [...smeta,...(rmeta||{})];
        // const dd = {...rdd,meta};
        // console.log('rdd',rdd,'rmeta',rmeta,'smeta',smeta,'dd',dd);
        // this.setState({ dd });
        //-------------------------------------
        this.setState({ dd: response.dd });
      } catch (error) {
        console.log("dd error", error);
        this.sessionError(error);
      }
    }, timer);
    this.setState({ ping: ping });
  }

  componentWillUnmount() {
    if (this.state.ping) clearInterval(this.state.ping);
  }

  sessionError(error: any) {
    const { type, name, title, data } = error || {};
    if (type === "error" && name === "fetcherror" && title === "Unauthorised") {
      const { failure, code } = data || {};
      if (failure === true && code === 401) {
        //session expired. reload page.
        //this.props.doRestart('Session Expired. reloading...');
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
    }
  }

  onFilter = async (data: any) => {
    console.log('onFilter',data);
    this.startLoading();
    const { start, end } = data;
    const baseparams = { s: "security", a: "dashfilter", sta: start, sto: end };
    const response = await bizpost(baseparams);
    console.log('onFilter.response',response);
    this.stopLoading();
    this.setState({ dd: response.dd });
  };

  getChannel(data: any) {
    const { meta, customers: chandata } = data;
    const chans = meta.filter(
      (val: any, idx: number, arr: any[]) => val && val.group === "customers"
    );
    const rtn = chans.reduce((acc: any[], rec: any, idx: number, arr: any) => {
      rec.value = chandata === null ? "" : chandata[rec.code];
      acc.push(rec);
      return acc;
    }, []);
    return rtn;
  }

  getMode(data: any) {
    const { meta, mode: modedata = {} } = data;
    const mdata = modedata === null ? {} : modedata;
    const modes = meta.filter(
      (val: any, idx: number, arr: any[]) => val && val.group === "mode"
    );
    const rtn = modes.reduce((acc: any[], rec: any, idx: number, arr: any) => {
      rec.value = modedata === null ? "" : mdata[rec.code];
      acc.push(rec);
      return acc;
    }, []);
    return rtn;
  }

  getInfo(data: any) {
    //console.log('info.data',data);
    const { meta, input } = data;
    const infos = meta.filter(
      (val: any, idx: number, arr: any[]) => val && val.group === "info"
    );
    const info = infos.reduce((acc: any[], rec: any, idx: number, arr: any) => {
      console.log("info.reduce.rec", rec, "source", input);
      rec.value = input[rec.name];
      console.log("info.reduce.rec", rec, "source", input);
      acc.push(rec);
      return acc;
    }, []);
    return info;
  }

  getDaily(data: any) {
    return Daily;
  }

  getMonthly(data: any) {
    return Monthly;
  }

  getRecent(data: any) {
    return Recent;
  }

  getFeature(data: any) {
    return null;
  }

  onTabChange = (event: any, value: number) => {
    this.setState({ tabid: value });
  };

  render() {
    const { userData, theme, classes } = this.props;
    const { bd } = userData;
    const { eni } = bd || {};
    const { dd, loading, tabid } = this.state;
    const dashdata = dd || { monthly: [], source: {} };
    const infos = this.getInfo(dashdata);
    const modes = this.getMode(dashdata);
    const chans = this.getChannel(dashdata);

    const { meta, daily, monthly, recent, mode: feature } = dashdata;

    const Board = boards[tabid] || boards[1];

    return (
      <PageBase navigation="MPSMS / Summary Dashboard" subid={(bd || {}).eni}>
        <div className={classes.root}>
          <Grid container spacing={theme.breakpoints.up("sm") ? 3 : 1}>
            <Grid item xs={12}>
              <Header
                data={[]}
                tabid={tabid}
                onFilter={this.onFilter}
                onTabChange={this.onTabChange}
                icons
              />
            </Grid>
          </Grid>

          <Grid>

          <Board
            dd={dd}
            eni={eni}
            infos={infos}
            modes={modes}
            chans={chans}
            daily={daily}
            monthly={monthly}
            recent={recent}
          />

          </Grid>

          <ProgressDialog
            open={loading}
            loading={loading}
            startLoading={this.startLoading}
            stopLoading={this.stopLoading}
          />
        </div>
      </PageBase>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Dashboard);
