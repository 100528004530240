import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
//import {cyan, grey, pink, purple, orange,blue,brown,green,} from '@mui/material/colors';
// import {cyan, pink, purple, orange,blue,brown,green,} from '@mui/material/colors';
// import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
// import ListItem from '@mui/material/ListItem';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
//import Collapse from '@mui/material/Collapse';
// import ChevronLeft from '@mui/icons-material/ChevronLeft';
// import ChevronRight from '@mui/icons-material/ChevronRight';
// import MenuIcon from '@mui/icons-material/Menu';
// import ShoppingCart from '@mui/icons-material/ShoppingCart';
import Assessment from '@mui/icons-material/Assessment';
import MenuBox from './MenuBox';
import {colors} from '../../base';

const styles = (theme:Theme):any => ({
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  itemicon: {
    minWidth: 24,
    marginRight: 8,
  },
  parent: {
    margin: 50,
    justifyContent: 'flex-start',
    display: "flex",
    //justifyContent: "flex-end",
    
  },
  item: {
    //justifyContent: 'space-around',
  }
});

// const colors = [
//   cyan['50'],pink['50'],green['50'],orange['50'],blue['50'],purple['50'],brown['50'],
//   cyan['100'],pink['100'],green['100'],orange['100'],blue['100'],purple['100'],brown['100'],
//   cyan['200'],pink['200'],green['200'],orange['200'],blue['200'],purple['200'],brown['200'],
//   cyan['300'],pink['300'],green['300'],orange['300'],blue['300'],purple['300'],brown['300'],
//   cyan['400'],pink['400'],green['400'],orange['400'],blue['400'],purple['400'],brown['400'],
//   cyan['500'],pink['500'],green['500'],orange['500'],blue['500'],purple['500'],brown['500'],
//   cyan['600'],pink['600'],green['600'],orange['600'],blue['600'],purple['600'],brown['600'],
//   cyan['700'],pink['700'],green['700'],orange['700'],blue['700'],purple['700'],brown['700'],
//   cyan['800'],pink['800'],green['800'],orange['800'],blue['800'],purple['800'],brown['800'],
//   cyan['900'],pink['900'],green['900'],orange['900'],blue['900'],purple['900'],brown['900'],
// ];

class KanbanMenuParent extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      open: this.props.openImmediately,
    }
  }
  state = {
    open: this.props.openImmediately,
  };

  componentDidMount() {
    // So we only run this logic once.
    if (!this.props.openImmediately) {
      return;
    }

    // Center the selected item in the list container.
    const activeElement = document.querySelector(`.${this.props.classes.active}`);
    if (activeElement && activeElement.scrollIntoView) {
      activeElement.scrollIntoView({});
    }
  }

  render() {
    const {
      children,
      classes,
      onClick,
      openImmediately,
      title,
      index,
      key,
      menuid,
      //menucode,
      icon,
      divider,
      activeid,
      value,
      ...other
    } = this.props;
    console.log('index: ',index,menuid,colors[menuid],other);
    return (
      <React.Fragment>
        <Grid item xs={2} sm={2} className={classes.parent}>
          <MenuBox 
            className={classes.item}
            Icon={Assessment}
            icon={icon}
            color={colors[menuid]}
            title={title}
            value={""} //{value}
            onClick={onClick}
          />
        </Grid>
        {/* <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <div>
            <h3 className={classes.navigation}>{title}</h3>
            <Grid container spacing={2}>
              {children}
            </Grid>
          </div>
        </Collapse> */}
      </React.Fragment>
    );
  }
}

KanbanMenuParent.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  openImmediately: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

KanbanMenuParent.defaultProps = {
  openImmediately: false,
};

export default withStyles(styles)(KanbanMenuParent);
