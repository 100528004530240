import React, { Component } from "react";
import ReactDOM from "react-dom";

import { ThemeProvider } from "@mui/material/styles";
import { Theme } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
// import Paper from "@mui/material/Paper";
import AppBar from "@mui/material/AppBar";
// import Select from '@mui/material/Select';
//import { MenuItem } from '@mui/material/Menu';
// import TextField from "@mui/material/TextField";
//import Checkbox from '@mui/material/Checkbox';
import Button from "@mui/material/Button";
// import Backdrop from "@mui/material/Backdrop";
// import Snackbar from "@mui/material/Snackbar";
// import Alert from "@mui/material/Alert";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
// import IconButton from "@mui/material/IconButton";
// import Input from "@mui/material/Input";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
// import Visibility from "@mui/icons-material/Visibility";
// import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PersonIcon from "@mui/icons-material/Person";
import HelpIcon from "@mui/icons-material/Help";
// import {orange, grey, white} from '@mui/material/colors';
import MenuIcon from "@mui/icons-material/Menu";
import SecurityIcon from "@mui/icons-material/Security";
//import MUINotification from '../messages/MUINotification';
//import Alerts from '../messages/Alerts';
// import FormData from 'formdata-polyfill';
// import CenterGrid from "../layout/CenterGrid";
import { bizpost } from "../../ajax";
import ProgressDialog from "../dialogs/ProgressDialog";
// import { AlertTitle } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import FTextField from "../formfields/FTextField";
import FPasswordField from "../formfields/FPasswordField";
import FAutoComplete from "../formfields/FAutoComplete";
import { isValid } from "../form";
import LoginReset from "./LoginReset";

// const orange500 = orange[500];
// const grey500 = grey[500];

//alert instance
//const alertx = new Alerts('login');

const styles = (theme: Theme): any => ({
  formbox: {
    backgroundColor: "#FFFFFF",
    borderRadius: "0 15px 15px 0",
    // marginRight: -15,
  },
  loginbar: {
    borderRadius: "0 15px 0 0",
  },
  // loginbox: {
  //   //width: 450
  //   // margin:'50px auto',
  //   display: 'inline-block'
  // },
  loginform: {
    padding: theme.spacing(1),
    paddingLeft: 10,
    paddingRight: 10,
    width: 400,
  },
  titlebar: {
    // color: 'white',
    textAlign: "center",
    flex: 1,
  },
  // formControl: {
  //   margin: theme.spacing(1),
  // },
  // input: {
  //   margin: theme.spacing(1),
  // },
  actionbox: {
    display: "flex",
    justifyContent: "center",
    gap: 10,
  },
  action: {
    margin: "auto 5px",
  },
  linkbox: {
    display: "flex",
    justifyContent: "space-between",
  },
  link: {
    margin: theme.spacing(1),
    textTransform: "none",
  },
  // button: {
  //   margin: theme.spacing(1),
  // },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  // textField: {
  //   marginLeft: theme.spacing(1),
  //   marginRight: theme.spacing(1),
  //   //width: 200,
  // },
  // menu: {
  //   //width: 200,
  // },
  hide: {
    display: "none",
  },
  show: {
    display: "block",
  },
  logo: {
    cursor: "pointer",
    fontSize: 22,
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightLight,
    backgroundImage: "url(lib/img/logo.png)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "0 50%",
    backgroundColor: "transparent",
    paddingLeft: 5,
    paddingRight: 5,
    height: 56,
    width: 70,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  first: {
    color: "#FFC60B",
  },
  last: {
    color: "#FFffFF",
  },
  // backdrop: {
  //   zIndex: theme.zIndex.drawer + 1,
  //   color: '#fff',
  // },
  // alertdiv: {
  //   width: 400,
  //   height: 400,
  //   '& > * + *': {
  //     marginTop: theme.spacing(2),
  //   },
  // },
});

// declare module '@mui/material/Input' {
//   interface InputProps {
//     children: Component
//   }
// }

class LoginForm extends Component<any, any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      count: 0,
      valid: false,
      data: { contextid: 0, ctx: 0 },
      // sbopen: false,
      // sbmesg: '',
      // username: '',
      // password:'',
      showPassword: false,
      // context: props.context,
      // serviceid: 1,
      remember: false,
      reset: false,
      //drag: false,
      // notstatus: false,
      // nottype: 'info',
      // nottitle: 'Notification',
      // notmessage: 'Notification'
    };
  }

  startLoading = () => {
    this.setState({ loading: true });
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };

  setValid = (data: any) => {
    const param = [
      { id: "unm", req: 1 },
      { id: "pwd", req: 1 },
      { id: "ctx", req: 0 },
    ];
    const mode = "add";
    const valid = isValid(param, data, mode);
    this.setState({ valid });
  };

  onChange =
    (name: string) => (value: any, record: any, childs: any, reason: any) => {
      const { data } = this.state;
      const newdata = { ...data, [name]: value };
      this.setValid(newdata);
      this.setState((ps: any) => ({
        data: { ...ps.data, [name]: value },
        ...(childs ? { cuparams: { ...ps.cuparams, [name]: value } } : {}),
      }));
    };

  onMouseDown = (event: any) => {
    event.preventDefault();
  };

  onShow = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  onKeyPress = (ev: any) => {
    if (ev.key === "Enter") this.onClick();
  };

  onClick = async () => {
    try {
      const { home, apptheme } = this.props;
      const { data, remember } = this.state;
      var json = { s: "security", a: "login", ...data, rem: remember };

      this.startLoading();
      const response = await bizpost(json);
      console.log("login response ", (response || {}).success);
      if (!response.bd) {
        const err = response.ed || {};
        const {
          message = "request failed. user not found",
          type = "warn",
          title = "Login Error",
        } = err;
        this.onError(type, title, message);
      } else {
        ReactDOM.render(
          <ThemeProvider theme={apptheme}>
            {React.createElement(home, { udata: response })}
          </ThemeProvider>,
          document.getElementById("app")
        );
      }
    } catch (err) {
      const error: any = err || {};
      console.log("login failed: ");
      const {
        message = "request failed",
        type = "warn",
        title = "Login Error",
      } = error;
      this.onError(type, title, message);
    } finally {
      this.setState({ loading: false });
    }
  };

  onError = (type: string, title: string, message: string) => {
    const { onMessage } = this.props;
    if (typeof onMessage === "function") onMessage(type, title, message);
    else
      console.log("onMessage not a function... exiting...", typeof onMessage);
  };

  onCancel = () => {
    this.setState({ loading: false });
  };

  onReset = () => {
    this.setState({ reset: true });
  };

  onExit = () => {
    //console.log('onexit called');
    this.setState({ reset: false });
  };

  // onDrag = () => {
  //   const {drag} = this.state;
  //   this.setState({drag:!drag});
  // }

  showNotification = (message: string, type: string, title: string) => {
    this.onError(type, title, message);
  };

  getSelectedItem(){
    const item = this.props.options.find((opt:any)=>{
      if (opt.value == this.props.selectedValue)
        return opt;
    })
    return item || {};
  }

  render() {
    const { home, logincontext, classes } = this.props;
    const { loading, valid, data = {}, reset } = this.state;
    const { unm: username, pwd: password, ctx: contextid, ctx, contextid:contextidval } = data;
    const logosrc = "/api/logo/icon.png";
    // onMessage={onMessage}
    console.log(`ctx:${ctx}, contextid:${contextidval}`)

    return (
      <div className={classes.formbox}>
        <AppBar position="static" className={classes.loginbar}>
          <Toolbar>
            <div
              className={classes.logo}
              style={{ backgroundImage: `url(${logosrc})` }}
            />
            <Typography className={classes.first} variant="h5" component="h5">
              {"NURI 350"}
            </Typography>
            &nbsp;
            <Typography
              className={classes.last}
              variant="h5"
              component="h5"
            >{` ${"PROJECT"}`}</Typography>
          </Toolbar>
        </AppBar>

        <Typography variant="h4" className={classes.titlebar}>
          Login
        </Typography>

        <div>
          <form id="loginform" className={classes.loginform}>
            <ProgressDialog
              open={loading}
              loading={loading}
              startLoading={this.startLoading}
              stopLoading={this.stopLoading}
            />
            <LoginReset
              open={reset}
              //drag={drag}
              onClose={this.onExit}
              //onDrag={this.onDrag}
              showNotification={this.showNotification}
            />
            <div>
              <FTextField
                id={"unm"}
                label={"Username"}
                value={username}
                onChange={this.onChange("unm")}
                required={true}
                options={{
                  autoFocus: true,
                  helperText: "Enter your username here",
                  InputProps: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </div>
            <div>
              <FPasswordField
                id={"pwd"}
                label={"Password"}
                value={password}
                onChange={this.onChange("pwd")}
                onKeyPress={this.onKeyPress}
                required={true}
                options={{
                  helperText: "Enter your password here",
                  InputProps: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <SecurityIcon />
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </div>
            <div
              className={logincontext === true ? classes.show : classes.hide}
            >
              <FAutoComplete
                id="ctx"
                label="Login To:"
                config={{ t: "bcs", s: "context" }}
                value={contextid}
                onChange={this.onChange("ctx")}
                options={{
                  ioptions: {
                    //onError={this.onComboDataEerror}
                    helperText: "Please select your service context",
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <MenuIcon />
                        </InputAdornment>
                      ),
                    },
                  },
                }}
              />
            </div>
            <br />
            <div className={classes.linkbox}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.remember}
                      onChange={(event, checked) =>
                        this.setState({ remember: checked })
                      }
                    />
                  }
                  label="Remember"
                />
              </FormControl>
              <Button className={classes.link} onClick={this.onReset}>
                <HelpIcon className={classes.leftIcon} />
                <Typography variant="body1">Forgot Password?</Typography>
              </Button>
            </div>
            <br />
            <div className={classes.actionbox}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.action}
                onClick={this.onCancel}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.action}
                disabled={!valid}
                onClick={this.onClick}
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(LoginForm);
