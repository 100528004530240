import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import {pink} from '@mui/material/colors';
import {Tooltip, BarChart, Bar, ResponsiveContainer, XAxis, YAxis, CartesianGrid} from 'recharts';


const styles = (theme:Theme):any => ({
  paper: {
    backgroundColor: 'white',
    minHeight: 400,
    // overFlow: 'hidden'
  },
  div: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '95%',
    height: 300,
  },
  header: {
    color: 'white',
    backgroundColor: pink['600'],
    padding: 10,
    fontSize: 24,
    fontWeight: 300,
  },
  custom_tooltip:{
    lineHeight: 0.2,
    backgroundColor: 'white',
    padding: 5
  },
  nodata: {
    display: 'flex',
    justifyContent: 'center'
  },
  barchart:{
    marginTop: 20
  }
});

class MonthlyRequests extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      count: 0,
      mode: 'total', //amount
    };
  }

  // componentWillReceiveProps(nextProps) {
  //   if (this.props.page !== nextProps.page) {
  //       this.setState({page: nextProps.page});
  //   }
  // }

  convert = (data:any,mode:any) => {
    var names = ['','Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var rtn = data.map((item:any) =>(
      {name:names[item.dmonth],year:item.dyear,value:item[mode]}
    ));
    //console.log('month',rtn);
    return rtn;
  }

  customTooltip = ({active, payload, label}: any) => {
    const {classes} = this.props;
    if(payload === null || payload === undefined) return null;
    
    if(active){
      return(
        <div className={classes.custom_tooltip}>
          <p>{label}</p>
          <p className={classes.revenue}>{`${payload[0].name} : ${'GHS '+payload[0].value}`}</p>
        </div>
      )
    }
    return null;
  }

  render() {
    const {data,title='Monthly Meters',classes} = this.props;
    const {mode} = this.state;
    const chartdata = this.convert(data,mode);
    //  width={730} height={250}
    return (
      <Paper className={classes.paper}>
        <div className={classes.header}>{title}</div>
        <div className={classes.div}>
          <ResponsiveContainer >
            {data.length>0?
            <BarChart className={classes.barchart} data={chartdata} >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name"/>
              <YAxis dataKey="value" />
              {/* <Tooltip /> */}
              <Tooltip content={<this.customTooltip />} />
              <Bar dataKey="value" name="Meters" fill={pink['500']}/>
            </BarChart>
            : <div className={classes.nodata}>No data to show</div>}
          </ResponsiveContainer>
        </div>
      </Paper>
    );
  }
}

MonthlyRequests.propTypes = {
  data: PropTypes.array
};

export default withStyles(styles, { withTheme: true })(MonthlyRequests);
