import { formatJsonLocation as fjl, formatLocation } from "../../components/renderers";
import { formatJsonDataset as fjd } from "../../components/renderers";

const xlsaccept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';

const serviceconfig = {
  
  
  // account: {
  //   service:{
  //     name:'account',
  //     title:'Account',
  //     key:'rid',
  //     num: false,
  //     fields: ['rid','nam', 'shc', 'dsc', 'ati','atn', 'eni', 'enm', 'eti', 'aky', 'bii', 'bin', 'bic', 'cre', 'lgo', 'exi', 'sts', 'ast', 'stp'],
  //     params: {nam:'Service',shc:'Code',dsc:'Description',sts:'Status'},
  //     grid: [['nam','Name'],['shc','Code'],['dsc','Description'],['atc','Type'],['enm','Entity'],['cre','Credits'],['sts','Status'],['stp','Stamp']],
  //     bar: {pag:true,btn:['add','edit','del','view','man','wiz']},
  //     menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
  //     eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
  //           {id:'shc',label:'Code',type:'tf',req:0,ops:{}},
  //           {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
  //           {id:'ati',label:'Account Type',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'accounttype'}},
  //           {id:'eni',label:'Entity',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entity'}},
  //           {id:'cre',label:'Credit',type:'nf',req:0,ops:{}},
  //           {id:'lgo',label:'Logo',type:'if',req:0,ops:{preview:true}},
  //           {id:'sts',label:'Status',type:'fa',req:1,ops:{},cp:{t:'lcs',s:'status'}},
  //         ],
  //   },
  //   create:true,
  //   actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
  //   panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  // },

  // activity: {
  //   service: {
  //     name: 'activity',
  //     title: 'Activity',
  //     key: 'rid',
  //     num: true,
  //     fields:['rid','cli','cln','clc','sti','stn','svi','svn','asi','asn','dcd','tki','rfi','par','exi',
  //             'dtl','sts','ast','stp'],
  //     params: {nam:'Context',shc:'Code',dsc:'Description',dsi:'District',rgi:'Region',sts:'Status'},
  //     grid: [['dcd','Date'],['rfi','Reference'],['svn','Service'],['stn','Type'],['cln','Client'],['clc','Client Code'],['tki','Tracking ID'],['asn','Activity Status'],['stp','Stamp']],
	// 	  bar: {pag:true,btn:['view']},
  //     menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
  //     eform:[{id:'cli',label:'Client',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'client'}},
  //            {id:'svi',label:'Service',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'service'}},
  //           {id:'ati',label:'Activity Status',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'activitystatus'}},
  //           {id:'tki',label:'Tracking ID',type:'rf',req:0,ops:{}},
  //           {id:'rfi',label:'Reference ID',type:'rf',req:0,ops:{}},
  //           {id:'par',label:'Parameters',type:'rf',req:0,ops:{}},
  //           {id:'exi',label:'Extra Info',type:'rf',req:0,ops:{}},
  //           {id:'dtl',label:'Details',type:'rf',req:0,ops:{}},
  //           {id:'sts',label:'Record Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
  //           {id:'stp',label:'Last Updated',type:'rf',req:0,ops:{}},
  //         ],
  //   },
  //   create:true,
  //   actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
  //   panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
  //   formatters: {grid: {exi:fjd}}
  // },

  audit: {
    service:{
      name:'audit',
      title:'Audit',
      key:'rid',
      num: false,
      fields: ['rid','nam', 'shc', 'dsc', 'ati','atn', 'eni', 'enm', 'eti', 'aky', 'bii', 'bin', 'bic', 'cre', 'lgo', 'exi', 'sts', 'ast', 'stp'],
      params: {nam:'Service',shc:'Code',dsc:'Description',sts:'Status'},
      grid: [['nam','Name'],['shc','Code'],['dsc','Description'],['atc','Type'],['enm','Entity'],['cre','Credits'],['sts','Status'],['stp','Stamp']],
      bar: {pag:true,btn:['add','edit','del','view','man','wiz']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
            {id:'shc',label:'Code',type:'tf',req:0,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'ati',label:'Account Type',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'accounttype'}},
            {id:'eni',label:'Entity',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entity'}},
            {id:'cre',label:'Credit',type:'nf',req:0,ops:{}},
            {id:'lgo',label:'Logo',type:'ff',req:0,ops:{}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },
  
  // apiaccess: {
  //   service:{
  //     name:'apiaccess',
  //     title:'API Access',
  //     key:'rid',
  //     num: true,
  //     fields: ['rid','eni', 'enm', 'aci', 'acn','api', 'apn', 'aco', 'mco', 'mst', 'sts', 'ast', 'stp'],
  //     params: {enm:'Entity',acn:'Account',apn:'API',sts:'Status'},
  //     grid: [['enm','Entity'],['acn','Account'],['apn','API'],['aco','API Code'],['mco','Master'],['mst','MStatus'],['sts','Status'],['stp','Stamp']],
  //     bar: {pag:true,btn:['add','edit','del','view','man','wiz']},
  //     menu: {mnu:[{t:'Entity',v:'enm'},{t:'Account',v:'acn'}],def:'enm'},
  //     eform:[
  //       {id:'aci',label:'Account',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'account'},off:['edit']},
  //       {id:'api',label:'API',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'api'},off:['edit']}, 
  //       {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},     
  //     ],
  //   },
  //   create: 'server',
  //   actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
  //   panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  // },

  // bank: {
  //   service: {
  //     name: 'bank',
  //     title: 'Bank',
  //     key: 'rid',
  //     num: true,
  //     fields: ['rid','nam','shc','scd','swc','eni','enn','enc','etn','lci','lcn','geo','exi','dtl','sts','stp'],
  //     grid: [['nam','Name'],['etn','Type'],['shc','Code'],['scd','Sort'],['swc','Swift'],['lcn','Location'],['geo','Geo'],['exi','Info'],['sts','Status'],['stp','Stamp']],
  //     bar: {pag:true,btn:['add','edit','del','view'] },
  //     menu: {mnu:[{t:'Name',v:'nam'},{ t:'Code',v:'shc'}],def:'nam' },//*
  //     form: ['aci','svi','sts'],
  //     eform: [
  //       { id:'nam',label:'Name',type:'tf',req:1,ops:{} },
  //       { id:'shc',label:'Code',type:'tf',req:1,ops:{} },
  //       { id:'scd',label:'Sort Code',type:'tf',req:1,ops:{} },
  //       { id:'swc',label:'Swift Code',type:'tf',req:1,ops:{} },
  //       { id:'eni',label:'Entity',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entity'} },
  //       { id:'lci',label:'Location',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'location' } },
  //       { id:'exi',label:'Extra Info',type:'jf',req:0,ops:{} },
  //       { id:'dtl',label:'Details',type:'ta',req:0,ops:{} },
  //       { id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status' } }
  //     ],
  //   },
  //   create:true,
  //   actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
  //   panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
  //   formatters: {grid: {geo:fjl,exi:fjd}}
  // },

  // bankaccounts: {
  //   service: {
  //     name: 'bankaccounts',
  //     title: 'Bank Accounts',
  //     key: 'rid',
  //     num: true,
  //     fields: ['rid','bki','bkn','bkc','esc','ope','sus','cle','ext','exi','sts','ast','stp'],
  //     grid: [['bkn','Name'],['bkc','Code'],['esc','Escrow'],['ope','Operation'],['sus','Suspense'],['cle','Clearing'],['ext','Extra'],['exi','Info'],['sts','Status'],['stp','Stamp']],
  //     bar: {pag:true,btn:['add','edit','del','view'] },
  //     menu: {mnu:[{t:'Name',v:'bkn'},{ t:'Code',v:'bkc'}],def:'bkn' },//*
  //     form: ['aci','svi','sts'],
  //     eform: [
  //       { id:'bki',label:'Bank Account ',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'branch' } },
  //       { id:'esc',label:'Escrow Account',type:'tf',req:0,ops:{pattern:'[0-9]{16}'} },
  //       { id:'ope',label:'Operations Account',type:'tf',req:0,ops:{pattern:'[0-9]{16}'} },
  //       { id:'sus',label:'Suspense Account',type:'tf',req:0,ops:{pattern:'[0-9]{16}'} },
  //       { id:'cle',label:'Clearing Account',type:'tf',req:0,ops:{pattern:'[0-9]{16}'} },
  //       { id:'ext',label:'Extra Account',type:'tf',req:0,ops:{pattern:'[0-9]{16}'} },
  //       { id:'exi',label:'Extra Info',type:'jf',req:0,ops:{} },
  //       { id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status' } }
  //     ],
  //   },
  //   create:true,
  //   actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
  //   panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
  //   formatters: {grid: {exi:fjd}}
  // },

  // branch: {
  //   service: {
  //     name: 'branch',
  //     title: 'Branch',
  //     key: 'rid',
  //     num: true,
  //     fields: ['rid','nam','shc','scd','swc','bki','bkn','bkc','bti','btn','btc','lci','lcn','geo','dtl','sts','stp'],
  //     grid: [['nam','Branch'],['btn','Type'],['shc','Code'],['scd','Sort'],['swc','Swift'],['bkn','Bank'],['lcn','Location'],['geo','Geo'],['sts','Status'],['stp','Stamp']],
  //     bar: {pag:true,btn:['add','edit','del','view'] },
  //     menu: {mnu:[{t:'Branch',v:'nam'},{ t:'Code',v:'bcd'}],def:'nam' },//*
  //     form: ['aci','svi','sts'],
  //     eform: [
  //       { id:'nam',label:'Name',type:'tf',req:1,ops:{} },
  //       { id:'shc',label:'Code',type:'tf',req:1,ops:{} },
  //       { id:'scd',label:'Sort Code',type:'tf',req:1,ops:{} },
  //       { id:'swc',label:'Swift Code',type:'tf',req:1,ops:{} },
  //       { id:'bki',label:'Bank',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'bank'} },
  //       { id:'bti',label:'Type',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'branchtype'} },
  //       { id:'lci',label:'Location',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'location' } },
  //       { id:'dtl',label:'Details',type:'ta',req:0,ops:{} },
  //       { id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status' } }
  //     ],
  //   },
  //   create:true,
  //   actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
  //   panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
  //   formatters: {grid: {geo:fjl}}
  // },

  // branchaccounts: {
  //   service: {
  //     name: 'branchaccounts',
  //     title: 'Branch Accounts',
  //     key: 'rid',
  //     num: true,
  //     fields: ['rid','bki','bkn','bri','brn','brc','bti','btn','btc','bes','bop','bsu','bcl','bex','exi','sts','ast','stp'],
  //     grid: [['brn','Name'],['brc','Code'],['bes','Escrow'],['bop','Operation'],['bsu','Suspense'],['bcl','Clearing'],['bex','Extra'],['exi','Info'],['sts','Status'],['stp','Stamp']],
  //     bar: {pag:true,btn:['add','edit','del','view'] },
  //     menu: {mnu:[{t:'Name',v:'brn'},{ t:'Code',v:'brc'}],def:'brn' },//*
  //     form: ['aci','svi','sts'],
  //     eform: [
  //       { id:'bri',label:'Branch',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'branch' } },
  //       { id:'bes',label:'Escrow Account',type:'tf',req:0,ops:{pattern:'[0-9]{16}'} },
  //       { id:'bop',label:'Operations Account',type:'tf',req:0,ops:{pattern:'[0-9]{16}'} },
  //       { id:'bsu',label:'Suspense Account',type:'tf',req:0,ops:{pattern:'[0-9]{16}'} },
  //       { id:'bcl',label:'Clearing Account',type:'tf',req:0,ops:{pattern:'[0-9]{16}'} },
  //       { id:'bex',label:'Extra Account',type:'tf',req:0,ops:{pattern:'[0-9]{16}'} },
  //       { id:'exi',label:'Extra Info',type:'jf',req:0,ops:{} },
  //       { id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status' } }
  //     ],
  //   },
  //   create:true,
  //   actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
  //   panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
  //   formatters: {grid: {exi:fjd}}
  // },

  client: {
    service:{
      name:'client',
      title:'client',
      num: true,
      fields:['rid','nam','shc','eni','enn','lci','lcn','lcc','aky','acl','con','exi','dtl','sts','ast','stp'],
      grid:[['nam','Client Name'],['lcn','Location'],['con','Contact'],['exi','Extra Info'],['dtl','Details'],['stp','Stamp']],
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Share',v:'tot'}],def:'nam'},
      eform:[
        {id:'nam',label:'Name',type:'tf',req:1,ops:{}},
        {id:'shc',label:'Short Code',type:'tf',req:1,ops:{}},
        {id:'eni',label:'Entity',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entity'}},
        {id:'lci',label:'Location',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'location'}},
        {id:'aky',label:'Access Key',type:'mf',req:1,ops:{params:{s:'client',a:'access'},conf:{recField:'rid'}}},
        {id:'con',label:'Contact',type:'tf',req:1,ops:{}},
        {id:'exi',label:'Extra Info',type:'jf',req:0,ops:{}},
        {id:'dtl',label:'Details',type:'tf',req:0,ops:{}},
        {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},]
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
    formatters: {grid: {exi:fjd}}
  },

  
  // clientrequest: {
  //   service:{
  //     name:'clientrequest',
  //     title:'Client Request',
  //     num: true,
  //     fields:['rid','rqi','dcd','cli','cln','clc','rto','rbo','rin','cco','chn','sco','stx','exi','sts','stp'],
  //     grid  : [['dcd','Date'],['rqi','RequestID'],['cln','Client'],['rto','Token'],['cco','Code'],['chn','Channel'],['sco','Statuscode'],['sts','Status'],['stp','Stamp']],
  //     menu: {mnu:[{t:'Date',v:'sdt'},{t:'Code',v:'cco'}],def:'cco'},
  //     eform:[
  //       {id:'rqi',label:'Request ID',type:'tf',col:1,req:1,ops:{} },
  //       {id:'dcd',label:'Transaction Date',type:'tf',col:1,req:1,ops:{} },
  //       {id:'cli',label:'Client',type:'fa',col:1,req:1,ops:{}, cp:{t:'bcs',s:'client'} },
  //       {id:'rto',label:'Request Token',type:'tf',col:1,req:1,ops:{} },
  //       {id:'rbo',label:'Request Body',type:'jf',col:1,req:0,ops:{} },
  //       {id:'rin',label:'Request Info',type:'jf',col:1,req:1,ops:{} },
  //       {id:'cco',label:'Client Code',type:'tf',col:2,req:1,ops:{} },
  //       {id:'chn',label:'Channel Code',type:'tf',col:2,req:1,ops:{} },
  //       {id:'sco',label:'Status Code',type:'tf',col:2,req:1,ops:{} },
  //       {id:'stx',label:'Status Text',type:'tf',col:2,req:0,ops:{} },
  //       {id:'exi',label:'Extrainfo',type:'jf',col:2,req:0,ops:{}},
  //       {id:'sts',label:'Status',type:'fa',col:2,req:0,ops:{},cp:{t:'lcs',s:'status' } }
  //     ],
  //   },
  //   create:true,
  //   actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
  //   panels: {add:'column',edit:'column',delete:'dialog',view:'column'},
  //   formatters: {grid: {rbo:fjd,rin:fjd,exi:fjd}}
  // },

  // commission: {
  //   service:{
  //     name:'commission',
  //     title:'Commission',
  //     num: true,
  //     fields:['rid','nam','svi','sti','svn','cti','ctn','ctc','val','dsc','tot','sts','ast','stp'],
  //     grid:[['nam','Name'],['svn','Service'],['ctn','Commssion Type'],['val','Value'],['tot','Share'],['dsc','Description'],['stp','Stamp']],
  //     menu: {mnu:[{t:'Name',v:'nam'},{t:'Share',v:'tot'}],def:'nam'},
  //     eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
  //       {id:'svi',label:'Service',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'service'}},
  //       {id:'cti',label:'Commision Type',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'commissiontype'}},
  //       {id:'val',label:'Value',type:'tf',req:1,ops:{},off:['edit']},
  //       {id:'tot',label:'Share',type:'tf',req:1,ops:{},off:['edit']},
  //       {id:'dsc',label:'Description',type:'tf',req:0,ops:{}},
  //       {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},]
  //   },
  //   create:true,
  //   actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
  //   panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  // },

  // commissionshare: {
  //   service:{
  //     name:'commissionshare',
  //     title:'Commission Share',
  //     num: true,
  //     fields:['rid','nam','cmi','cmn','svi','tot','eti','etn','cti','ctn','ctc','val','dsc','sts','ast','stp'],
  //     grid:[['nam','Name'],['cmn','Commission Name'],['ctn','Commission Type'],['etn','Entity Type'],['val','Value'],['tot','Share'],['dsc','Description'],['stp','Stamp']],
  //     menu: {mnu:[{t:'Name',v:'nam'},{t:'Share',v:'tot'}],def:'nam'},
  //     eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
  //       {id:'cmi',label:'Commission',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'commission'}},
  //       {id:'eti',label:'Entity Type',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entitytype'}},
  //       {id:'cti',label:'Commission Type',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'commissiontype'}},
  //       {id:'val',label:'Value',type:'tf',req:1,ops:{}},
  //       {id:'dsc',label:'Description',type:'tf',req:0,ops:{}},
  //       {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},]
  //   },
  //   create:true,
  //   actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
  //   panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  // },

  // compliance: {
  //   service:{
  //     name:'compliance',
  //     title:'Compliance',
  //     num: true,
  //     fields:['rid','cti','ctn','ctc','nam','iti','itn','itc','idn','adr','fun','pur','tid','rfn','rqi','dcd','tdt',
  //             'chn','xti','xtn','xtc','cli','cln','clc','aga','tco','usi','usn','tid','tac','tyc','xti','xtn','xtc',
  //             'bri','brn','brc','dba','cda','cur','amt','dtl','rsn','rst','exi','sts','stp'],
  //     grid:[['dcd','Date'],['ctn','Type'],['nam','Sender'],['idn','Identity'],['rfn','Reference'],['rqi','RequestID'],
  //           ['cln','Client'],['xtc','TxType'],['brn','RCB'],['cur','Currency'],['amt','Amount'],['rst','Status']],
  //     menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
  //     eform:[],
  //     // eform:[{id:'dcd',label:'Request Date',type:'rf',req:0,ops:{}},
  //     //       {id:'urf',label:'User Contact',type:'rf',req:0,ops:{}},
  //     //       {id:'svc',label:'Service',type:'rf',req:0,ops:{}},
  //     //       {id:'tri',label:'Tracking ID',type:'rf',req:0,ops:{}},
  //     //       {id:'rfi',label:'Reference',type:'rf',req:0,ops:{}},
  //     //       {id:'src',label:'Source Reference',type:'rf',req:0,ops:{}},
  //     //       {id:'stn',label:'Status',type:'rf',req:0,ops:{}},
  //     //       {id:'stp',label:'Last Updated',type:'rf',req:0,ops:{}},
  //     //     ],
  //   },
  //   create:true,
  //   actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
  //   panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
  //   formatters: {grid: {exi:fjd}}
  // },

  configuration: {
    service: {
      name: 'configuration',
      title: 'Configuration',
      key: 'rid',
      num: true,
      fields: ['rid','nam', 'shc', 'dsc', 'val', 'sts', 'ast', 'stp'],
      params: {nam:'Name',shc:'Code',dsc:'Description',val:'Value',sts:'Status'},
      grid: [['nam','Name'],['shc','Code'],['dsc','Description'],['val','Value'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
            {id:'shc',label:'Code',type:'tf',req:0,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'val',label:'Value',type:'tf',req:1,ops:{}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  connection: {
    service:{
      name:'connection',
      title:'Connection',
      num: true,
      fields:['rid','nam','shc','dsc','val','sts','ast','stp'],
      grid:[['nam','Name'],['shc','Code'],['val','Value'],['dsc','Description'],['sts','Status'],['stp','Stamp']],
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
        {id:'shc',label:'Code',type:'tf',req:1,ops:{}},
        {id:'val',label:'Value',type:'tf',req:1,ops:{}},
        {id:'dsc',label:'Description',type:'tf',req:0,ops:{}},
        {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},]
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  //"rid","rfn","dsc","adt","ddt","odi","odr","odd","odt","sui","sun","suc","cri","crn","crc","fai","fan","fac","qts","tot","dby","rby","dcd","rmk","exi","sts","ast","stp"

  consignment: {
    service: {
      name: 'consignment',
      title: 'Consignment',
      key: 'rid',
      num: true,
      fields: ['rid','rfn','dsc','adt','ddt','odi','odr','odd','odt','sui','sun','suc','cri','crn','crc','fai','fan','fac','qts','tot','dby','rby','dcd','rmk','exi','sts','ast','stp'],
      grid: [['rfn','Reference'],['dsc','Description'],['odr','Order'],['adt','Arrival'],['ddt','Delivery'],['suc','Supplier'],['crc','Clearer'],['fac','Facility'],['tot','Total'],['qts','Quantities'],['exi','Info'],['sts','Status'],['stp','Stamp']],
      bar: {pag:true,btn:['add','edit','del','view'] },
      menu: {mnu:[{t:'Reference',v:'rfn'}],def:'rfn' },//*
      form: ['aci','svi','sts'],
      eform: [
        { id:'nam',label:'Name',type:'tf',req:1,ops:{} },
        { id:'shc',label:'Code',type:'tf',req:1,ops:{} },
        { id:'scd',label:'Sort Code',type:'tf',req:1,ops:{} },
        { id:'swc',label:'Swift Code',type:'tf',req:1,ops:{} },
        { id:'eni',label:'Entity',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entity'} },
        { id:'lci',label:'Location',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'location' } },
        { id:'exi',label:'Extra Info',type:'jf',req:0,ops:{} },
        { id:'dtl',label:'Details',type:'ta',req:0,ops:{} },
        { id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status' } }
      ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
    formatters: {grid: {qts:fjd,exi:fjd}}
  },

  consitems: {
    service: {
      name: 'consitems',
      title: 'Consignment Items',
      key: 'rid',
      num: true,//
      fields: ['rid','cni','cnr','odi','odr','mmi','mmc','mti','mtc','qty','dtl','exi','sts','ast','stp'],
      grid: [['cnr','Consignment'],['odr','Order'],['mmc','Model'],['mtc','Type'],['qty','Quantity'],['exi','Info'],['sts','Status'],['stp','Stamp']],
      bar: {pag:true,btn:['add','edit','del','view'] },
      menu: {mnu:[{t:'Reference',v:'cnr'}],def:'cnr' },//*
      form: ['aci','svi','sts'],
      eform: [
        { id:'nam',label:'Name',type:'tf',req:1,ops:{} },
        { id:'shc',label:'Code',type:'tf',req:1,ops:{} },
        { id:'scd',label:'Sort Code',type:'tf',req:1,ops:{} },
        { id:'swc',label:'Swift Code',type:'tf',req:1,ops:{} },
        { id:'eni',label:'Entity',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entity'} },
        { id:'lci',label:'Location',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'location' } },
        { id:'exi',label:'Extra Info',type:'jf',req:0,ops:{} },
        { id:'dtl',label:'Details',type:'ta',req:0,ops:{} },
        { id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status' } }
      ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
    formatters: {grid: {exi:fjd}}
  },

  context: {
    service: {
      name: 'context',
      title: 'Context',
      key: 'rid',
      num: true,
      fields: ['rid','nam', 'shc', 'dsc', 'aky', 'acl', 'sts', 'ast', 'stp'],
      params: {nam:'Context',shc:'Code',dsc:'Description',aky:'Access Key',acl:'Access Level',sts:'Status'},
      grid: [['nam','Name'],['shc','Code'],['dsc','Description'],['aky','Access Key'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
            {id:'shc',label:'Code',type:'tf',req:0,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'aky',label:'Access Key',type:'mf',req:1,ops:{params:{s:'context',a:'access'},conf:{recField:'rid'}}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  customer: {
    service:{
      name:'customer',
      title:'Customer',
      fields:['rid','fnm','mnm','lnm','nam','gdi','mbn','dcd','aci','acn','acc','bci','coi','coy','cos',
              'ano','anm','cui','cuy','cus','bri','bnm','bcd','iti','itn','itc','idn','idy','tel','knm',
              'krn','kcn','rmk','sts','ast','stp'],
      grid: [['dcd','Date'],['nam','Name'],['gdi','Gender'],['acn','Account'],['bnm','Branch'],['bci','Reference'],['mbn','Mobile'],['idy','ID'],
             ['coy','Country'],['sts','Status'],['stp','Stamp']],
      menu: {mnu:[{t:'Customer',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'fnm',label:'Firstname',type:'tf',req:1,col:1,ops:{}},
            {id:'mnm',label:'Middlename',type:'tf',req:0,col:1,ops:{}},
            {id:'lnm',label:'Lastname',type:'ta',req:0,col:1,ops:{}},
            {id:'gdi',label:'Gender',type:'fa',req:0,col:1,ops:{},cp:{t:'lcs',s:'gender'}},
            {id:'mbn',label:'Mobile',type:'tf',req:0,col:1,ops:{type:'tel'}},
            {id:'aci',label:'Service',type:'fa',req:1,col:1,ops:{},cp:{t:'bcs',s:'idtype'}},
            {id:'bci',label:'ServiceID',type:'tf',req:0,col:1,ops:{type:'tel'}},
            {id:'coi',label:'Country',type:'fa',req:1,col:1,ops:{},cp:{t:'bcs',s:'idtype'}},
            {id:'iti',label:'ID Type',type:'fa',req:1,col:1,ops:{},cp:{t:'bcs',s:'idtype'}},
            {id:'idn',label:'ID No',type:'tf',req:0,col:1,ops:{} },
            {id:'cui',label:'Currency',type:'fa',req:1,col:1,ops:{},cp:{t:'bcs',s:'idtype'}},
            {id:'bri',label:'Branch',type:'fa',req:1,col:1,ops:{},cp:{t:'bcs',s:'idtype'}},
            {id:'ano',label:'Account No',type:'tf',req:0,col:1,ops:{type:'tel'}},
            {id:'anm',label:'Account Name',type:'tf',req:0,col:1,ops:{type:'tel'}},
            {id:'ctn',label:'Contact',type:'tf',req:0,col:1,ops:{type:'tel'}},
            {id:'knm',label:'Kin Name',type:'tf',req:0,col:1,ops:{type:'tel'}},
            {id:'kre',label:'Kin Relation',type:'tf',req:0,col:1,ops:{}},
            {id:'kco',label:'Kin Contact',type:'tf',req:0,col:1,ops:{type:'tel'}},
            {id:'rmk',label:'Remarks',type:'ta',req:0,col:1,ops:{}},
            {id:'sts',label:'Status',type:'fa',req:0,col:1,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {
      add:'wizard',edit:'column',delete:'dialog',view:'simple',man:'tab',close:'dialog'
    }
  },

  dashmeta: {
    service:{
      name:'dashmeta',
      title:'Dashmeta',
      fields:['rid','nam','shc','dsc','dti','dtn','dtc','ttl','dgp','dfm','dva','dun',
              'col','pal','ico','exi','sts','ast','stp'],
      grid: [['nam','Name '],['shc','Code'],['dtn','Dashtype Name'],['ttl','Tittle'],['dgp','Data Group'],
             ['dfm','Data Format'],['dva','Data Value'],['exi','Extra Info'],['sts','Status'],['stp','Stamp']],
             //':nam,:shc,:dsc,:dti,:dtn,:dtc,:ttl,:dgp,:dfm,:dva,:dun,:col,:pal,:ico,:exi,:sts'
      menu: {mnu:[{t:'Customer',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,col:1,ops:{}},
            {id:'shc',label:'Shortcode',type:'tf',req:1,col:1,ops:{}},
            {id:'dti',label:'Dash Type ID',type:'fa',req:1,col:1,ops:{},cp:{t:'bcs',s:'dashtype'}},
            {id:'dtn',label:'Dash Type Name',type:'tf',req:1,col:1,ops:{}},
            {id:'dtc',label:'Dash Type Code',type:'tf',req:0,col:1,ops:{}},
            {id:'ttl',label:'Tittle',type:'tf',req:1,col:1,ops:{}},
            {id:'dgp',label:'Data Group',type:'tf',req:0,col:1,ops:{}},
            {id:'dfm',label:'Data Format',type:'tf',req:0,col:1,ops:{} },
            {id:'dva',label:'Data Value',type:'tf',req:0,col:2,ops:{}},
            {id:'dun',label:'Data Unit',type:'tf',req:0,col:2,ops:{}},
            {id:'col',label:'Color',type:'tf',req:0,col:2,ops:{}},
            {id:'pal',label:'Palette',type:'tf',req:0,col:2,ops:{}},
            {id:'ico',label:'Icon',type:'tf',req:0,col:2,ops:{}},
            {id:'dsc',label:'Description',type:'tf',req:0,col:2,ops:{}},
            {id:'exi',label:'Extra Info',type:'jf',req:0,col:2,ops:{}},
            {id:'sts',label:'Status',type:'fa',req:0,col:2,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {
      add:'column',edit:'column',delete:'dialog',view:'column',man:'tab',close:'dialog'
    },
    formatters: {grid: {exi:fjd}}
  },

  device: {
    service: {
      name: 'device',
      title: 'Device',
      key: 'rid',
      num: true,
      fields: ['rid','nam','shc','dsc','dti','dtn','dtc','opc','dcd','exi','sts','ast','stp'],
      params: {nam:'Device',shc:'Code',dsc:'Description',dti:'Type',opc:'OpCode',sts:'Status'},
      grid: [['nam','Name'],['shc','Code'],['dsc','Description'],['dtn','Type'],['opc','Op Code'],['dcd','Created'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
            {id:'shc',label:'Code',type:'tf',req:0,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'rgi',label:'Region',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'region'}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  devicehistory: {
    service: {
      name: 'devicehistory',
      title: 'Device History',
      key: 'rid',
      num: true,
      fields: ['rid','dvi','dvn','dvc','dvo','dti','dtc','sti','stn','stc','pdi','bno','cod','dcd','exi','sts','ast','stp'],
      params: {dvi:'Device',sti:'Station',dvc:'Code',dvo:'Device Code',dti:'Type',cod:'OpCode',sts:'Status'},
      grid: [['dvn','Device'],['dvo','DeviceCode'],['dtc','Type'],['stn','Station'],['bno','Production'],['cod','Service Code'],['dcd','Created'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['view']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
            {id:'shc',label:'Code',type:'tf',req:0,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'rgi',label:'Region',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'region'}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {view:'View'},
    panels: {view:'simple'}
  },

  devicestation: {
    service: {
      name: 'devicestation',
      title: 'Device Station',
      key: 'rid',
      num: true,
      fields: ['rid','dvi','dvn','dvc','dvo','dti','dtc','sti','stn','stc','pdi','bno','cod','dcd','exi','sts','ast','stp'],
      params: {dvi:'Device',sti:'Station',dvc:'Code',dvo:'Device Code',dti:'Type',cod:'OpCode',sts:'Status'},
      grid: [['dvn','Device'],['dvo','DeviceCode'],['dtc','Type'],['stn','Station'],['bno','Production'],['cod','Service Code'],['dcd','Created'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
            {id:'shc',label:'Code',type:'tf',req:0,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'rgi',label:'Region',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'region'}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  district: {
    service: {
      name: 'district',
      title: 'District',
      key: 'rid',
      num: true,
      fields: ['rid','nam','shc','dsc','rgi','rgn','sts','ast','stp'],
      params: {nam:'Context',shc:'Code',dsc:'Description',rgi:'Region',sts:'Status'},
      grid: [['nam','Name'],['shc','Code'],['dsc','Description'],['rgn','Region'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
            {id:'shc',label:'Code',type:'tf',req:0,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'rgi',label:'Region',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'region'}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  entity: {
    service: {
      name: 'entity',
      title: 'Entity',
      key: 'rid',
      num: true,
      fields: ['rid','nam','eti','ety','adr','loc','ct1','ct2','fax','eml','web','com','ila','pla','sts','ast','stp'],
      params: {nam:'Name',eti:'EntityTypeId',ety:'EntityType',adr:'Address',sts:'Status'},
      grid: [['nam','Name'],['ety','Type'],['loc','Location'],['ct1','Contact'],['eml','Email'],['web','Web'],
             ['pla','PLA'],['ila','ILA'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Contact',v:'ct1'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',col:1,req:1,ops:{}},
            {id:'eti',label:'Entity Type',type:'fa',col:1,req:1,ops:{},cp:{t:'bcs',s:'entitytype'}},
            {id:'adr',label:'Address',type:'ta',col:1,req:0,ops:{}},
            {id:'loc',label:'Location',type:'tf',col:1,req:0,ops:{}},
            {id:'ct1',label:'Contact',type:'tf',col:1,req:0,ops:{}},
            {id:'ct2',label:'Phone',type:'tf',col:1,req:0,ops:{type:'tel'}},
            {id:'fax',label:'Fax',type:'tf',col:2,req:0,ops:{}},
            {id:'eml',label:'Email',type:'tf',col:2,req:0,ops:{type:'email'}},
            {id:'web',label:'web',type:'tf',col:2,req:0,ops:{type:'url'}},
            {id:'com',label:'Comments',type:'ta',col:2,req:0,ops:{}},
            {id:'ila',label:'ILA',type:'nf',col:2,req:0,ops:{type:'range',min:0,max:10},off:['add','edit']},
            {id:'pla',label:'PLA',type:'nf',col:2,req:0,ops:{type:'range',min:0,max:10},off:['add','edit']},
            {id:'sts',label:'Status',type:'fa',col:2,req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'column',edit:'column',delete:'dialog',view:'column'}
  },

  entitytype: {
    service: {
      name: 'entitytype',
      title: 'Entity Type',
      key: 'rid',
      num: true,
      fields: ['rid','nam', 'shc', 'dsc', 'pls', 'sts', 'ast', 'stp'],
      params: {nam:'Context',shc:'Code',dsc:'Description',pls:'Privileges',sts:'Status'},
      grid: [['nam','Name'],['shc','Code'],['dsc','Description'],['pls','Privileges'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
            {id:'shc',label:'Code',type:'tf',req:0,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'pls',label:'Privileges',type:'ta',req:1,ops:{}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  error: {
    service: {
      name: 'error',
      title: 'Error',
      key: 'rid',
      num: true,
      fields: ['rid','nam', 'shc', 'dsc', 'msg', 'sts', 'ast', 'stp'],
      params: {nam:'Error',shc:'Code',dsc:'Description',acl:'Message',sts:'Status'},
      grid: [['nam','Name'],['shc','Code'],['dsc','Description'],['msg','Message'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view','man','wiz']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
            {id:'shc',label:'Code',type:'tf',req:0,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'msg',label:'Message',type:'tf',req:1,ops:{}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },



  // clearer: {
  //   service: {
  //     name: 'clearer',
  //     title: 'Clearing Agent',
  //     key: 'rid',
  //     num: true,
  //     fields: ['rid','nam','shc','dsc','eni','enm','svc','exi','sts','ast','stp'],
  //     params: {nam:'Name',shc:'Code',dsc:'Description',eni:'Entity',svc:'Service Code',exi:'Extra Info',sts:'Status'},
  //     grid: [['nam','Name'],['shc','Code'],['dsc','Description'],['enm','Entity'],['svc','Service Code'],['exi','Extra Info'],['sts','Status'],['stp','Stamp']],
	// 	  bar: {pag:true,btn:['add','edit','del','view']},
  //     menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
  //     eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
  //           {id:'shc',label:'Code',type:'tf',req:1,ops:{}},
  //           {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
  //           {id:'eni',label:'Entity',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entity'}},
  //           {id:'svc',label:'Service Code',type:'nf',req:1,ops:{}},
  //           {id:'exi',label:'ExtraInfo',type:'jf',req:0,ops:{}},
  //           {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
  //         ],
  //   },
  //   create:true,
  //   actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
  //   panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  // },

  // cbcustomer: {
  //   service:{
  //     name:'cbcustomer',
  //     title:'CB Customer Events',
  //     num: true,
  //     fields:['rid','ope','xdt','inp','aut','xtm','ore','nre','cnm'],
  //     grid  : [['xdt','Date'],['xtm','Time'],['ope','Operation'],['inp','Inputter'],['aut','Authoriser'],['ore','Old'],['nre','New'],['cnm','Company']],
  //     menu: {mnu:[{t:'Mobile',v:'mbn'}],def:'mbn'},
  //     eform:[
  //       {id:'xdt',label:'Date',type:'rf',col:1,req:0,ops:{}},
  //       {id:'xtm',label:'Time',type:'rf',col:1,req:0,ops:{}},
  //       {id:'ope',label:'Operation',type:'rf',col:1,req:0,ops:{}},
  //       {id:'inp',label:'Inputter',type:'rf',col:1,req:0,ops:{}},
  //       {id:'aut',label:'Authoriser',type:'rf',col:1,req:0,ops:{}},
  //       {id:'ore',label:'Old Record',type:'rf',col:1,req:0,ops:{}},
  //       {id:'nre',label:'New Record',type:'rf',col:1,req:0,ops:{}},
  //       {id:'cnm',label:'Company',type:'rf',col:1,req:0,ops:{}}
  //     ],
  //   },
  //   create:false,
  //   panels: { 
  //     view:'column',close:'dialog',check:'dialog',print:'result'
  //   },
  //   //formatters: {grid: {ore:fjd,nre:fjd}}
  // },

  // cblist: {
  //   service:{
  //     name:'cblist',
  //     title:'CB Events',
  //     num: true,
  //     fields:['rid','xui','ope','xdt','inp','aut','xtm','ore','nre','cnm'],
  //     grid  : [['xui','UserID'],['xdt','Date'],['xtm','Time'],['ope','Operation'],['inp','Inputter'],['aut','Authoriser'],['ore','Old'],['nre','New'],['cnm','Company']],
  //     menu: {mnu:[{t:'UserID',v:'xui'},{t:'Start Date',v:'sdt'},{t:'End Date',v:'edt'}],def:'sdt'},
  //     eform:[
  //       {id:'xui',label:'UserID',type:'rf',req:0,ops:{}},
  //       {id:'xdt',label:'Date',type:'rf',req:0,ops:{}},
  //       {id:'xtm',label:'Time',type:'rf',req:0,ops:{}},
  //       {id:'ope',label:'Operation',type:'rf',req:0,ops:{}},
  //       {id:'inp',label:'Inputter',type:'rf',req:0,ops:{}},
  //       {id:'aut',label:'Authoriser',type:'rf',req:0,ops:{}},
  //       {id:'ore',label:'Old Record',type:'rf',req:0,ops:{}},
  //       {id:'nre',label:'New Record',type:'rf',req:0,ops:{}},
  //       {id:'cnm',label:'Company',type:'rf',req:0,ops:{}}
  //       // {id:'xui',label:'UserID',type:'rf',col:1,req:0,ops:{}},
  //       // {id:'xdt',label:'Date',type:'rf',col:1,req:0,ops:{}},
  //       // {id:'xtm',label:'Time',type:'rf',col:1,req:0,ops:{}},
  //       // {id:'ope',label:'Operation',type:'rf',col:1,req:0,ops:{}},
  //       // {id:'inp',label:'Inputter',type:'rf',col:1,req:0,ops:{}},
  //       // {id:'aut',label:'Authoriser',type:'rf',col:1,req:0,ops:{}},
  //       // {id:'ore',label:'Old Record',type:'rf',col:1,req:0,ops:{}},
  //       // {id:'nre',label:'New Record',type:'rf',col:1,req:0,ops:{}},
  //       // {id:'cnm',label:'Company',type:'rf',col:1,req:0,ops:{}}
  //     ],
  //   },
  //   create:false,
  //   panels: { 
  //     view:'simple',close:'dialog',check:'dialog',print:'result'
  //   },
  //   //formatters: {grid: {ore:fjd,nre:fjd}}
  // },

  // cbuser: {
  //   service:{
  //     name:'cbuser',
  //     title:'CB User Events',
  //     num: true,
  //     fields:['rid','ope','xdt','inp','aut','xtm','ore','nre','cnm'],
  //     grid  : [['xui','UserID'],['xdt','Date'],['xtm','Time'],['ope','Operation'],['inp','Inputter'],['aut','Authoriser'],['ore','Old'],['nre','New'],['cnm','Company']],
  //     menu: {mnu:[{t:'UserID',v:'xui'},{t:'Start Date',v:'sdt'},{t:'End Date',v:'edt'}],def:'sdt'},
  //     eform:[
  //       {id:'xui',label:'UserID',type:'rf',col:1,req:0,ops:{}},
  //       {id:'xdt',label:'Date',type:'rf',col:1,req:0,ops:{}},
  //       {id:'xtm',label:'Time',type:'rf',col:1,req:0,ops:{}},
  //       {id:'ope',label:'Operation',type:'rf',col:1,req:0,ops:{}},
  //       {id:'inp',label:'Inputter',type:'rf',col:1,req:0,ops:{}},
  //       {id:'aut',label:'Authoriser',type:'rf',col:1,req:0,ops:{}},
  //       {id:'ore',label:'Old Record',type:'rf',col:1,req:0,ops:{}},
  //       {id:'nre',label:'New Record',type:'rf',col:1,req:0,ops:{}},
  //       {id:'cnm',label:'Company',type:'rf',col:1,req:0,ops:{}}
  //     ],
  //   },
  //   create:false,
  //   panels: { 
  //     view:'column',close:'dialog',check:'dialog',print:'result'
  //   },
  //   //formatters: {grid: {ore:fjd,nre:fjd}}
  // },

  // cbdetail: {
  //   service:{
  //     name:'cbcustomer',
  //     title:'CB Event Details',
  //     num: true,
  //     fields:['rid','ope','xdt','inp','aut','xtm','ore','nre','cnm'],
  //     grid  : [['xdt','Date'],['xtm','Time'],['ope','Operation'],['inp','Inputter'],['aut','Authoriser'],['ore','Old'],['nre','New'],['cnm','Company']],
  //     menu: {mnu:[{t:'UserID',v:'xui'}],def:'xui'},
  //     eform:[
  //       {id:'xdt',label:'Date',type:'rf',col:1,req:0,ops:{}},
  //       {id:'xtm',label:'Time',type:'rf',col:1,req:0,ops:{}},
  //       {id:'ope',label:'Operation',type:'rf',col:1,req:0,ops:{}},
  //       {id:'inp',label:'Inputter',type:'rf',col:1,req:0,ops:{}},
  //       {id:'aut',label:'Authoriser',type:'rf',col:1,req:0,ops:{}},
  //       {id:'ore',label:'Old Record',type:'rf',col:1,req:0,ops:{}},
  //       {id:'nre',label:'New Record',type:'rf',col:1,req:0,ops:{}},
  //       {id:'cnm',label:'Company',type:'rf',col:1,req:0,ops:{}}
  //     ],
  //   },
  //   create:false,
  //   panels: { 
  //     view:'column',close:'dialog',check:'dialog',print:'result'
  //   },
  //   //formatters: {grid: {ore:fjd,nre:fjd}}
  // },

  // event: {
  //   service:{
  //     name:'event',
  //     title:'Events',
  //     num: true,
  //     fields:['rid','dbi','dcd','act','ent','fld','rec','uid','nam','cmi','chn','mbn','unm','cha','ami','amc','usi','sts','ast','stp'],
  //     grid  : [['dcd','Date'],['usi','Auditee'],['amc','Mode'],['act','Action'],['nam','User'],['cmi','Customer'],['chn','Channel'],['mbn','Mobile']],
  //     menu: {mnu:[{t:'Auditee',v:'usi'}],def:'usi'},
  //     eform:[
  //           {id:'dbi',label:'DB ID',type:'rf',col:1,req:0,ops:{}},
  //           {id:'dcd',label:'Date Created',type:'rf',col:1,req:0,ops:{}},
  //           {id:'amc',label:'Audit Mode',type:'rf',col:1,req:0,ops:{}},
  //           {id:'act',label:'Action',type:'rf',col:1,req:0,ops:{}},
  //           {id:'ent',label:'Module',type:'rf',col:1,req:0,ops:{}},
  //           {id:'fld',label:'Field',type:'rf',col:1,req:0,ops:{}},
  //           {id:'uid',label:'MUser ID',type:'rf',col:2,req:0,ops:{}},
  //           {id:'nam',label:'MUser Name',type:'rf',col:2,req:0,ops:{}},
  //           {id:'cmi',label:'CustomerID',type:'rf',col:2,req:0,ops:{}},
  //           {id:'chn',label:'Channel',type:'rf',col:2,req:0,ops:{}},
  //           {id:'mbn',label:'Mobile',type:'rf',col:2,req:0,ops:{}},
  //           {id:'cha',label:'Changes',type:'jf',col:2,req:0,ops:{}},
  //           {id:'usi',label:'Auditee',type:'rf',col:1,req:0,ops:{}},
  //           {id:'ast',label:'Status',type:'rf',col:1,req:0,ops:{}},
  //           {id:'stp',label:'Stamp',type:'rf',col:1,req:0,ops:{}}
  //     ],
  //   },
  //   create:false,
  //   panels: { 
  //     view:'column',close:'dialog',check:'dialog',print:'result'
  //   },
  //   //formatters: {grid: {req:fjd,res:fjd,exi:fjd}}
  // },

  // frequency: {
  //   service: {
  //     name: 'frequency',
  //     title: 'Transaction Frequency',
  //     key: 'rid',
  //     num: true,
  //     fields: ['rid','nam','shc','dsc','per','pes','uni','tot','sts','ast','stp'],
  //     params: {nam:'Context',shc:'Code',dsc:'Description',dsi:'District',rgi:'Region',sts:'Status'},
  //     grid: [['nam','Name'],['shc','Code'],['dsc','Description'],['per','Period'],['tot','Total'],['sts','Status'],['stp','Stamp']],
	// 	  bar: {pag:true,btn:['add','edit','del','view']},
  //     menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
  //     eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
  //           {id:'shc',label:'Code',type:'tf',req:0,ops:{}},
  //           {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
  //           {id:'per',label:'Period',type:'tf',req:1,ops:{}},
  //           {id:'tot',label:'Total',type:'tf',req:1,ops:{}},
  //           {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
  //         ],
  //   },
  //   create:true,
  //   actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
  //   panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  // },

  // limit: {
  //   service: {
  //     name: 'limit',
  //     title: 'Transaction Limit',
  //     key: 'rid',
  //     num: true,
  //     fields: ['rid','aci','acn','max','fri','frn','frc','frp','frv','sts','ast','stp'],
  //     params: {aci:'AgentCategory',max:'Max Limit',fri:'Frequency',frp:'Period',frv:'Limit',sts:'Status'},
  //     grid: [['acn','Category'],['max','Maximum'],['frc','Frequency'],['frn','Frequency'],['frp','Period'],['frv','Limit'],['sts','Status'],['stp','Stamp']],
	// 	  bar: {pag:true,btn:['add','edit','del','view']},
  //     menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
  //     eform:[{id:'aci',label:'Category',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'agentcategory'}},
  //           {id:'max',label:'Maximum',type:'nf',req:0,ops:{}},
  //           {id:'fri',label:'Frequency',type:'fa',req:0,ops:{},cp:{t:'bcs',s:'frequency'}},
  //           {id:'tot',label:'Total',type:'tf',req:1,ops:{}},
  //           {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
  //         ],
  //   },
  //   create:true,
  //   actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
  //   panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  // },

  location: {
    service: {
      name: 'location',
      title: 'Location',
      key: 'rid',
      num: true,
      fields: ['rid','nam','shc','dsc','dsi','dsn','rgi','rgn','geo','exi','sts','ast','stp'],
      params: {nam:'Context',shc:'Code',dsc:'Description',dsi:'District',rgi:'Region',sts:'Status'},
      grid: [['nam','Name'],['shc','Code'],['dsc','Description'],['dsn','District'],['rgn','Region'],['geo','Geolocation'],['exi','ExtraInfo'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
            {id:'shc',label:'Code',type:'tf',req:0,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'dsi',label:'District',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'district'}},
            {id:'geo',label:'Geolocation',type:'gl',req:1,ops:{}},
            {id:'exi',label:'ExtraInfo',type:'jf',req:0,ops:{}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
    formatters: {grid: {geo:fjl,exi:fjd}}
  },

  loglevel: {
    service: {
      name: 'loglevel',
      title: 'Log Level',
      key: 'rid',
      num: true,
      fields: ['rid','stn','sty','sti','dsc','sts','ast','stp'],
      params: {nam:'Setting',shc:'Code',typ:'Type',val:'Value',dtl:'Details',sts:'Status'},
      grid: [['stn','Name'],['dsc','Description'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view']},
      menu: {mnu:[{t:'Setting',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'stn',label:'Name',type:'rf',req:1,ops:{},off:['edit']},
            {id:'sty',label:'Status Type',type:'rf',req:1,ops:{},off:['edit']},
            {id:'sti',label:'Status Type ID',type:'rf',req:1,ops:{},off:['edit']},
            {id:'dsc',label:'Description',type:'rf',req:1,ops:{},off:['edit']},
            {id:'sts',label:'Status',type:'fa',req:1,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  // master: {
  //   service:{
  //     name:'master',
  //     title:'Master',
  //     key: 'rid',
  //     num: true,
  //     fields:['rid','uco','nam','mbn','dcd','msi','coi','coy','coc','bal','cui','cuy','cuc','iti','itn',
  //             'itc','idn','ide','sti','stn','stc','ctn','knm','kre','kco','exi','sts','ast','stp'],
  //     grid: [['nam','Name'],['mbn','Mobile'],['coy','Country'],['itn','ID Type'],['ino','ID No']
  //           ,['cuc','Currency'],['bal','Balance'],['stn','Status'],['dcd','Created'],['stp','Stamp']],
  //     menu: {mnu:[{t:'User',v:'nam'},{t:'Mobile',v:'mbn'}],def:'mbn'},
  //     eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
  //           {id:'mbn',label:'Mobile',type:'tf',req:0,ops:{type:'tel'}},
  //           {id:'coi',label:'Country',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'country'}},
  //           {id:'cui',label:'Currency',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'currency'}},
  //           {id:'bal',label:'Balance',type:'nf',req:0,ops:{}},
  //           {id:'iti',label:'ID Type',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'idtype'}},
  //           {id:'idn',label:'ID No',type:'tf',req:0,ops:{} },
  //           {id:'sti',label:'User Status',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'mobilestatus'}},
  //           {id:'ctn',label:'Contact',type:'tf',req:1,ops:{} },
  //           {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
  //         ],
  //   },
  //   create:true,
  //   actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
  //   panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  // },

  // message: {
  //   service:{
  //     name:'mobilemessage',
  //     title:'Mobile Messages',
  //     num: true,
  //     fields:['rid','rfn','dcd','aci','acn','acc','mti','mtn','mtc','ttl','dtl','top','exi','sts','ast','stp'],
  //     grid  : [['dcd','Date'],['rfn','Reference'],['mtn','Type'],['ttl','Title'],['top','Topic']
  //             ,['sts','Status'],['stp','Stamp']],
  //     menu: {mnu:[{t:'Reference',v:'rfn'},{t:'Title',v:'ttl'}],def:'ttl'},
  //     eform:[ {id:'rfn',label:'Reference',type:'rf',req:0,ops:{},off:['add']},
  //             {id:'dcd',label:'Date Created',type:'rf',req:0,ops:{},off:['add']},
  //             {id:'aci',label:'Account',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'account'}},
  //             {id:'mti',label:'Type',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'mobilemessagetype'}},
  //             {id:'ttl',label:'Title',type:'tf',req:1,ops:{}},
  //             {id:'dtl',label:'Message',type:'ta',req:0,ops:{}},
  //             {id:'top',label:'Topic',type:'tf',req:0,ops:{}},
  //             {id:'exi',label:'Extra Info',type:'rf',req:0,ops:{},off:['add']},
  //             {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}}
  //           ],
  //   },
  //   create: true,
  //   actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
  //   panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  // },

  
  //['ast',''],['bno',''],['cni',''],['dcd',''],['ddt',''],['dsc',''],['exi',''],['fac',''],['fai',''],['mac',''],['mai',''],['man',''],['mcc',''],['mmc',''],['mmi',''],['msc',''],['msi',''],['mtc',''],['mti',''],['odi',''],['odt',''],['pdi',''],['pmi',''],['pmn',''],['prc',''],['pri',''],['psc',''],['psi',''],['rid',''],['rmk',''],['sno',''],['spc',''],['spi',''],['ssn',''],['stp',''],['sts',''],['suc',''],['sui','']
  //'ast,bno,cni,dcd,ddt,dsc,exi,fac,fai,mac,mai,man,mcc,mmc,mmi,msc,msi,mtc,mti,odi,odt,pdi,pmi,pmn,prc,pri,psc,psi,rid,rmk,sno,spc,spi,ssn,stp,sts,suc,sui'
  //"rid","sno","ssn","dsc","dcd","man","mmi","mmc","mai","mac","spi","spc","mti","mtc","mcc","cni","ddt","odi","odt","sui","suc","pdi","bno","fai","fac","pri","prc","psi","psc","pmi","pmn","msi","msc","rmk","exi","sts","ast","stp"
  meter: {
    service:{
      name:'meter',
      title:'Meter',
      num: true,
      pageMode: 'remote',
      params: {ast:'',bno:'',cni:'',dcd:'',ddt:'',dsc:'',exi:'',fac:'',fai:'',mac:'',mai:'',man:'',mcc:'',mmc:'',mmi:'',msc:''
              ,msi:'',mtc:'',mti:'',odi:'',odt:'',pdi:'',pmi:'',pmn:'',prc:'',pri:'',psc:'',psi:'',rid:'',rmk:'',sno:'',spc:''
              ,spi:'',ssn:'',stp:'',sts:'',suc:'',sui:''},
      fields:['rid','sno','ssn','dsc','dcd','man','mmi','mmc','mai','mac','spi','spc','mti','mtc','mcc','cni','ddt','odi','odt',
              'sui','suc','pdi','bno','fai','fac','pri','prc','psi','psc','pmi','pmn','msi','msc','rmk','exi','sts','ast','stp'],
      //fields:['rid','rfn','dcd','aci','acn','acc','mti','mtn','mtc','ttl','dtl','top','exi','sts','ast','stp'],
      //grid: [['ast',''],['bno',''],['cni',''],['dcd',''],['ddt',''],['dsc',''],['exi',''],['fac',''],['fai',''],['mac',''],['mai',''],['man',''],['mcc',''],['mmc',''],['mmi',''],['msc',''],['msi',''],['mtc',''],['mti',''],['odi',''],['odt',''],['pdi',''],['pmi',''],['pmn',''],['prc',''],['pri',''],['psc',''],['psi',''],['rid',''],['rmk',''],['sno',''],['spc',''],['spi',''],['ssn',''],['stp',''],['sts',''],['suc',''],['sui','']],
      grid  : [['dcd','Registered'],['sno','MeterNo'],['ssn','STS'],['mtc','Type'],['mcc','Code'],['ddt','Delivered'],
               ['odt','Ordered'],['bno','Batch'],['psc','PStatus'],['msc','MStatus'],['sts','Status'],['stp','Stamp']],
      menu: {mnu:[{t:'Reference',v:'rfn'},{t:'Title',v:'ttl'}],def:'ttl'},
      eform:[ {id:'rfn',label:'Reference',type:'rf',req:0,ops:{},off:['add']},
              {id:'dcd',label:'Date Created',type:'rf',req:0,ops:{},off:['add']},
              {id:'aci',label:'Account',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'account'}},
              {id:'mti',label:'Type',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'mobilemessagetype'}},
              {id:'ttl',label:'Title',type:'tf',req:1,ops:{}},
              {id:'dtl',label:'Message',type:'ta',req:0,ops:{}},
              {id:'top',label:'Topic',type:'tf',req:0,ops:{}},
              {id:'exi',label:'Extra Info',type:'rf',req:0,ops:{},off:['add']},
              {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}}
            ],
    },
    create: false,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  // mobilereport: {
  //   service:{
  //     name:'mobilereport',
  //     title:'Mobile Report',
  //     num: true,
  //     fields:['rid','rfn','dcd','aci','acn','acc','rti','rtn','rtc','svi','svn','svc','sti','stn','stc','sei','nam',
  //             'mbn','ctn','loc','lon','ttl','dtl','top','exi','sts','ast','stp'],
  //     grid  : [['dcd','Date'],['rfn','Reference'],['rtn','Type'],['svn','Severity'],['stn','Status'],
  //             ['nam','Sender'],['mbn','Mobile'],['loc','Location'],['ttl','Title'],['top','Topic']
  //             ,['sts','Status'],['stp','Stamp']],
  //     menu: {mnu:[{t:'Reference',v:'rfn'},{t:'Title',v:'ttl'}],def:'ttl'},
  //     eform:[ {id:'rfn',label:'Reference',type:'rf',req:0,ops:{}},
  //             {id:'dcd',label:'Date Created',type:'rf',req:0,ops:{}},
  //             {id:'aci',label:'Account',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'account'}},
  //             {id:'mti',label:'Type',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'mobilereporttype'}},
  //             {id:'svi',label:'Severity',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'severity'}},
  //             {id:'sti',label:'Status',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'reportstatus'}},
  //             {id:'nam',label:'Sender',type:'rf',req:0,ops:{}},
  //             {id:'mbn',label:'Mobile',type:'rf',req:0,ops:{}},
  //             {id:'loc',label:'Location',type:'rf',req:0,ops:{}}, //TODO: add mapfield
  //             {id:'ttl',label:'Title',type:'rf',req:0,ops:{}},
  //             {id:'dtl',label:'Message',type:'rf',req:0,ops:{}},
  //             {id:'top',label:'Topic',type:'rf',req:0,ops:{}},
  //             {id:'exi',label:'Extra Info',type:'rf',req:0,ops:{}},
  //             //{id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}}
  //           ],
  //   },
  //   create:true,
  //   actions: {view:'View'},
  //   panels: { view:'result' },
  //   formatters: {
  //     grid: {loc:formatLocation}
  //   }
  // },

  // mobilerequest: {
  //   service:{
  //     name:'mobilerequest',
  //     title:'Mobile Notifications',
  //     num: true,
  //     //fields:['rid','mtt','snm','smb','smi','rnm','rmb','rmi','cuc','amt','dcd','rfi'],
  //     fields:['rid','dcd','rfi','mtt','snm','smb','rnm','rmb','cuc','amt','dsc','tst'],
  //     grid  : [['dcd','Date'],['rfi','Reference'],['mtt','Type'],['snm','Sender Name'],['smb','Sender Mobile']
  //             ,['rnm','Recipient'],['rmb','Recipient Mobile'],['cuc','Currency'],['amt','Amount'],['dsc','Purpose'],['tst','Status']],
  //     menu: {mnu:[{t:'Sender',v:'snm'},{t:'Sender Mobile',v:'smb'}],def:'smb'},
  //     eform:[],
  //   },
  //   create:false,
  //   actions: {view:'View'},
  //   panels: { view:'result' }
  // },

  // mobiletxn: {
  //   service:{
  //     name:'mobiletxn',
  //     title:'Mobile Updates',
  //     num: true,
  //     //fields:['rid','mtt','snm','smb','smi','rnm','rmb','rmi','cuc','amt','dcd','rfi'],
  //     fields:['rid','dcd','rfi','mtt','snm','smb','rnm','rmb','cuc','amt','pur'],
  //     grid  : [['dcd','Date'],['rfi','Reference'],['mtt','Type'],['snm','Sender Name'],['smb','Sender Mobile']
  //             ,['rnm','Recipient'],['rmb','Recipient Mobile'],['cuc','Currency'],['amt','Amount'],['pur','Purpose']],
  //     menu: {mnu:[{t:'Sender',v:'snm'},{t:'Sender Mobile',v:'smb'}],def:'smb'},
  //     eform:[],
  //   },
  //   create:false,
  //   actions: {view:'View'},
  //   panels: { view:'result' }
  // },

  // mobileuser: {
  //   service:{
  //     name:'mobileuser',
  //     title:'Mobile Subscribers',
  //     key: 'rid',
  //     num: true,
  //     fields:['rid','uco','nam','mbn','dcd','msi','coi','coy','coc','bal','cui','cuy','cuc','iti','itn',
  //             'itc','idn','ide','sti','stn','stc','ctn','knm','kre','kco','exi','sts','ast','stp'],
  //     grid: [['nam','Name'],['mbn','Mobile'],['coy','Country'],['itn','ID Type'],['idn','ID No']
  //           ,['cuc','Currency'],['bal','Balance'],['stn','Status'],['dcd','Created'],['stp','Stamp']],
  //     menu: {mnu:[{t:'User',v:'nam'},{t:'Mobile',v:'mbn'}],def:'mbn'},
  //     eform:[{id:'nam',label:'Name',type:'tf',req:0,ops:{}},
  //           {id:'fnm',label:'Names',type:'tf',req:1,ops:{}},
  //           {id:'lnm',label:'Surname',type:'tf',req:1,ops:{}},
  //           {id:'dob',label:'Date of Birth',type:'df',req:1,ops:{}},
  //           {id:'mbn',label:'Mobile',type:'tf',req:1,ops:{type:'tel'}},
  //           {id:'coi',label:'Country',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'country'}},
  //           {id:'cui',label:'Currency',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'currency'}},
  //           {id:'bal',label:'Balance',type:'nf',req:0,ops:{},off:['add','edit']},
  //           {id:'iti',label:'ID Type',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'idtype'}},
  //           {id:'idn',label:'ID No',type:'tf',req:0,ops:{} },
  //           {id:'sti',label:'User Status',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'mobilestatus'}},
  //           {id:'ctn',label:'Contact',type:'tf',req:0,ops:{} },
  //           //{id:'knm',label:'Kin Name',type:'tf',req:0,ops:{}},
  //           //{id:'kre',label:'Kin Relation',type:'tf',req:0,ops:{type:'eml'}},
  //           //{id:'kco',label:'Kin Contact',type:'tf',req:0,ops:{}},
  //           //{id:'exi',label:'Extra Info',type:'ta',req:0,ops:{}},
  //           {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'},off:['add']},
  //         ],
  //   },
  //   create:true,
  //   actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
  //   panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  // },
  //

  metercode: {
    service: {
      name: 'metercode',
      title: 'Meter Code',
      key: 'rid',
      num: true,
      fields: ['rid','mmi','mmc','mti','mtc','mco','exi','sts','ast','stp'],
      grid: [['mmc','Model'],['mtc','Type'],['mco','Code'],['exi','Info'],['sts','Status'],['stp','Stamp']],
      bar: {pag:true,btn:['add','edit','del','view'] },
      menu: {mnu:[{t:'Model',v:'mmc'}],def:'mmc' },//*
      form: ['aci','svi','sts'],
      eform: [
        { id:'nam',label:'Name',type:'tf',req:1,ops:{} },
        { id:'shc',label:'Code',type:'tf',req:1,ops:{} },
        { id:'scd',label:'Sort Code',type:'tf',req:1,ops:{} },
        { id:'swc',label:'Swift Code',type:'tf',req:1,ops:{} },
        { id:'eni',label:'Entity',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entity'} },
        { id:'lci',label:'Location',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'location' } },
        { id:'exi',label:'Extra Info',type:'jf',req:0,ops:{} },
        { id:'dtl',label:'Details',type:'ta',req:0,ops:{} },
        { id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status' } }
      ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
    formatters: {grid: {exi:fjd}}
  },

  meterdistribution: {
    service:{
      name:'meterdistribution',
      title:'Meter Distribution',
      num: true,
      pageMode: 'remote',
      params: {ast:'',bno:'',cni:'',dcd:'',ddt:'',dsc:'',exi:'',fac:'',fai:'',mac:'',mai:'',man:'',mcc:'',mmc:'',mmi:'',msc:''
              ,msi:'',mtc:'',mti:'',odi:'',odt:'',pdi:'',pmi:'',pmn:'',prc:'',pri:'',psc:'',psi:'',rid:'',rmk:'',sno:'',spc:''
              ,spi:'',ssn:'',stp:'',sts:'',suc:'',sui:''},
      //rid | mei | sno | ssn | mcd | mtc | mcc | bno | prc | psc | msc | csi | csn | mbn | cso | csa | dci | dcc | rgi | 
      //rgc | mii | mim | mic | dcd | rmk | exi | sts | ast | stp
      //'mmc','mai','mac','spi','spc','mti','mtc','mcc','cni','ddt','odi','odt',
      //'sui','suc','pdi','bno','fai','fac','pri',,'pmi','pmn','msi'
      fields:['rid','mei','sno','ssn','mcd','mcc','bno','prc','psc','msc','csi','csn','mbn','cso','csa','dci','dcc',
              'rgi','rgc','mii','mim','mic','dcd','rmk','exi','sts','ast','stp'],
      //fields:['rid','rfn','dcd','aci','acn','acc','mti','mtn','mtc','ttl','dtl','top','exi','sts','ast','stp'],
      //grid: [['ast',''],['bno',''],['cni',''],['dcd',''],['ddt',''],['dsc',''],['exi',''],['fac',''],['fai',''],['mac',''],['mai',''],['man',''],['mcc',''],['mmc',''],['mmi',''],['msc',''],['msi',''],['mtc',''],['mti',''],['odi',''],['odt',''],['pdi',''],['pmi',''],['pmn',''],['prc',''],['pri',''],['psc',''],['psi',''],['rid',''],['rmk',''],['sno',''],['spc',''],['spi',''],['ssn',''],['stp',''],['sts',''],['suc',''],['sui','']],
      grid  : [['dcd','Date'],['sno','MeterNo'],['ssn','STS'],['mtc','Type'],['mcc','Code'],['bno','Batch'],['prc','Project'],['csn','Customer'],
               ['mbn','Mobile'],['cso','CustNo'],['csa','Account'],['rgc','Region'],['dcc','District'],['mic','Installer'],['sts','Status'],['stp','Stamp']],
      menu: {mnu:[{t:'Mter',v:'sno'},{t:'Batch',v:'bno'}],def:'sno'},
      eform:[ {id:'rfn',label:'Reference',type:'rf',req:0,ops:{},off:['add']},
              {id:'dcd',label:'Date Created',type:'rf',req:0,ops:{},off:['add']},
              {id:'aci',label:'Account',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'account'}},
              {id:'mti',label:'Type',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'mobilemessagetype'}},
              {id:'ttl',label:'Title',type:'tf',req:1,ops:{}},
              {id:'dtl',label:'Message',type:'ta',req:0,ops:{}},
              {id:'top',label:'Topic',type:'tf',req:0,ops:{}},
              {id:'exi',label:'Extra Info',type:'rf',req:0,ops:{},off:['add']},
              {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}}
            ],
    },
    create: false,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },
  //rid | mei | sno | ssn | psi | psc | cod | col | ver | dcd | sts | ast | stp

  meterhistory: {
    service: {
      name: 'meterhistory',
      title: 'Meter History',
      key: 'rid',
      num: true,
      fields: ['rid','mei','sno','ssn','psi','psc','cod','col','ver','dcd','sts','ast','stp'],
      grid: [['sno','Meter'],['ssn','STS No'],['psc','State'],['ver','Version'],['dcd','Created'],['sts','Status'],['stp','Stamp']],
      bar: {pag:true,btn:['add','edit','del','view'] },
      menu: {mnu:[{t:'Meter',v:'sno'},{t:'STSNo',v:'ssn'}],def:'sno' },//*
      form: ['aci','svi','sts'],
      eform: [
        { id:'nam',label:'Name',type:'tf',req:1,ops:{} },
        { id:'shc',label:'Code',type:'tf',req:1,ops:{} },
        { id:'scd',label:'Sort Code',type:'tf',req:1,ops:{} },
        { id:'swc',label:'Swift Code',type:'tf',req:1,ops:{} },
        { id:'eni',label:'Entity',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entity'} },
        { id:'lci',label:'Location',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'location' } },
        { id:'exi',label:'Extra Info',type:'jf',req:0,ops:{} },
        { id:'dtl',label:'Details',type:'ta',req:0,ops:{} },
        { id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status' } }
      ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
    formatters: {grid: {exi:fjd}}
  },

  metermode: {
    service: {
      name: 'metermode',
      title: 'Meter Mode',
      key: 'rid',
      num: true,
      fields: ['rid','mei','sno','ssn','imi','imc','svc','exi','sts','ast','stp'],
      grid: [['sno','Meter'],['ssn','STS No'],['imc','Mode'],['svc','Code'],['exi','Info'],['sts','Status'],['stp','Stamp']],
      bar: {pag:true,btn:['add','edit','del','view'] },
      menu: {mnu:[{t:'Meter',v:'sno'},{t:'STSNo',v:'ssn'}],def:'sno' },//*
      form: ['aci','svi','sts'],
      eform: [
        { id:'nam',label:'Name',type:'tf',req:1,ops:{} },
        { id:'shc',label:'Code',type:'tf',req:1,ops:{} },
        { id:'scd',label:'Sort Code',type:'tf',req:1,ops:{} },
        { id:'swc',label:'Swift Code',type:'tf',req:1,ops:{} },
        { id:'eni',label:'Entity',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entity'} },
        { id:'lci',label:'Location',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'location' } },
        { id:'exi',label:'Extra Info',type:'jf',req:0,ops:{} },
        { id:'dtl',label:'Details',type:'ta',req:0,ops:{} },
        { id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status' } }
      ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
    formatters: {grid: {exi:fjd}}
  },

  //rid |   nam    |  shc  |      dsc       | mai |  man   |  mac   | spi | spc | ttr | tds | spe | ver | exi | sts | ast |stp

  metermodel: {
    service: {
      name: 'metermodel',
      title: 'Meter Model',
      key: 'rid',
      num: true,
      fields: ['rid','nam','shc','dsc','mai','man','mac','spi','spc','ttr','tds','spe','ver','exi','sts','ast','stp'],
      grid: [['nam','Model'],['shc','Code'],['man','Manufacturer'],['spc','Spec'],['ttr','TypeTest'],['tds','TDS'],['ver','Version'],['exi','Info'],['sts','Status'],['stp','Stamp']],
      bar: {pag:true,btn:['add','edit','del','view'] },
      menu: {mnu:[{t:'Model',v:'nam'},{t:'Code',v:'shc'}],def:'nam' },//*
      form: ['aci','svi','sts'],
      eform: [
        { id:'nam',label:'Name',type:'tf',req:1,ops:{} },
        { id:'shc',label:'Code',type:'tf',req:1,ops:{} },
        { id:'scd',label:'Sort Code',type:'tf',req:1,ops:{} },
        { id:'swc',label:'Swift Code',type:'tf',req:1,ops:{} },
        { id:'eni',label:'Entity',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entity'} },
        { id:'lci',label:'Location',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'location' } },
        { id:'exi',label:'Extra Info',type:'jf',req:0,ops:{} },
        { id:'dtl',label:'Details',type:'ta',req:0,ops:{} },
        { id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status' } }
      ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
    formatters: {grid: {exi:fjd}}
  },

  //'rid','tki','mei','sno','ssn','pgi','pgc','par','att','dvi','dvc','sti','stc','psi','psc','err','nts','ver','dcd','rmk','exi','sts','ast','stp'

  metersetting: {
    service: {
      name: 'metersetting',
      title: 'Meter Setting',
      key: 'rid',
      num: true,
      fields: ['rid','tki','mei','sno','ssn','pgi','pgc','par','att','dvi','dvc','sti','stc','psi','psc','err','nts','ver','dcd','rmk','exi','sts','ast','stp'],
      grid: [['tki','Trackid'],['sno','Meter'],['ssn','STS No'],['pgc','Group'],['dvc','Device'],['stc','Station'],['psc','ParamStatus'],['ver','Version'],['dcd','Created'],['sts','Status'],['stp','Stamp']],
      bar: {pag:true,btn:['add','edit','del','view'] },
      menu: {mnu:[{t:'Trackid',v:'tki'},{t:'Meter',v:'sno'},{t:'STSNo',v:'ssn'}],def:'sno' },//*
      form: ['aci','svi','sts'],
      eform: [
        { id:'nam',label:'Name',type:'tf',req:1,ops:{} },
        { id:'shc',label:'Code',type:'tf',req:1,ops:{} },
        { id:'scd',label:'Sort Code',type:'tf',req:1,ops:{} },
        { id:'swc',label:'Swift Code',type:'tf',req:1,ops:{} },
        { id:'eni',label:'Entity',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entity'} },
        { id:'lci',label:'Location',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'location' } },
        { id:'exi',label:'Extra Info',type:'jf',req:0,ops:{} },
        { id:'dtl',label:'Details',type:'ta',req:0,ops:{} },
        { id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status' } }
      ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
    formatters: {grid: {exi:fjd}}
  },

  meterspec: {
    service: {
      name: 'meterspec',
      title: 'Meter Spec',
      key: 'rid',
      num: true,
      fields: ['rid','nam','shc','dsc','ttr','tds','spe','ver','exi','sts','ast','stp'],
      grid: [['nam','Spec'],['shc','Code'],['ttr','TypeTest'],['tds','TDS'],['ver','Version'],['exi','Info'],['sts','Status'],['stp','Stamp']],
      bar: {pag:true,btn:['add','edit','del','view'] },
      menu: {mnu:[{t:'Spec',v:'nam'},{t:'Code',v:'shc'}],def:'nam' },//*
      form: ['aci','svi','sts'],
      eform: [
        { id:'nam',label:'Name',type:'tf',req:1,ops:{} },
        { id:'shc',label:'Code',type:'tf',req:1,ops:{} },
        { id:'scd',label:'Sort Code',type:'tf',req:1,ops:{} },
        { id:'swc',label:'Swift Code',type:'tf',req:1,ops:{} },
        { id:'eni',label:'Entity',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entity'} },
        { id:'lci',label:'Location',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'location' } },
        { id:'exi',label:'Extra Info',type:'jf',req:0,ops:{} },
        { id:'dtl',label:'Details',type:'ta',req:0,ops:{} },
        { id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status' } }
      ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
    formatters: {grid: {exi:fjd}}
  },

  meterstate: {
    service: {
      name: 'meterstate',
      title: 'Meter State',
      key: 'rid',
      num: true,
      fields: ['rid','mei','sno','ssn','psi','psc','cod','col','dcd','sts','ast','stp'],
      grid: [['sno','Meter'],['ssn','STS No'],['psc','State'],['dcd','Created'],['sts','Status'],['stp','Stamp']],
      bar: {pag:true,btn:['add','edit','del','view'] },
      menu: {mnu:[{t:'Meter',v:'sno'},{t:'STSNo',v:'ssn'}],def:'sno' },//*
      form: ['aci','svi','sts'],
      eform: [
        { id:'nam',label:'Name',type:'tf',req:1,ops:{} },
        { id:'shc',label:'Code',type:'tf',req:1,ops:{} },
        { id:'scd',label:'Sort Code',type:'tf',req:1,ops:{} },
        { id:'swc',label:'Swift Code',type:'tf',req:1,ops:{} },
        { id:'eni',label:'Entity',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entity'} },
        { id:'lci',label:'Location',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'location' } },
        { id:'exi',label:'Extra Info',type:'jf',req:0,ops:{} },
        { id:'dtl',label:'Details',type:'ta',req:0,ops:{} },
        { id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status' } }
      ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
    formatters: {grid: {exi:fjd}}
  },

  //'rid','rfn','odt','dsc','sui','sun','suc','dst','qts','tot','amt','cui','cuc','sby','rby','osi','osc','dcd','rmk','exi','sts','ast','stp'

  order: {
    service: {
      name: 'order',
      title: 'Order',
      key: 'rid',
      num: true,
      fields: ['rid','rfn','odt','dsc','sui','sun','suc','dst','qts','tot','amt','cui','cuc','sby','rby','osi','osc','dcd','rmk','exi','sts','ast','stp'],
      grid: [['rfn','Reference'],['odt','Date'],['dsc','Description'],['suc','Supplier'],['dst','Destination'],['qts','Quantities'],['tot','Total'],['sby','Submitted'],['rby','Received'],['osc','OrderStatus'],['dcd','Created'],['exi','Info'],['sts','Status'],['stp','Stamp']],
      bar: {pag:true,btn:['add','edit','del','view'] },
      menu: {mnu:[{t:'Reference',v:'rfn'}],def:'rfn' },//*
      form: ['aci','svi','sts'],
      eform: [
        { id:'nam',label:'Name',type:'tf',req:1,ops:{} },
        { id:'shc',label:'Code',type:'tf',req:1,ops:{} },
        { id:'scd',label:'Sort Code',type:'tf',req:1,ops:{} },
        { id:'swc',label:'Swift Code',type:'tf',req:1,ops:{} },
        { id:'eni',label:'Entity',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entity'} },
        { id:'lci',label:'Location',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'location' } },
        { id:'exi',label:'Extra Info',type:'jf',req:0,ops:{} },
        { id:'dtl',label:'Details',type:'ta',req:0,ops:{} },
        { id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status' } }
      ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
    formatters: {grid: {qts:fjd,exi:fjd}}
  },

  orderitems: {
    service: {
      name: 'orderitems',
      title: 'Order Items',
      key: 'rid',
      num: true,//
      fields: ['rid','odi','odr','mmi','mmc','mti','mtc','qty','dtl','exi','sts','ast','stp'],
      grid: [['odr','Order'],['mmc','Model'],['mtc','Type'],['qty','Quantity'],['exi','Info'],['sts','Status'],['stp','Stamp']],
      bar: {pag:true,btn:['add','edit','del','view'] },
      menu: {mnu:[{t:'Reference',v:'cnr'}],def:'cnr' },//*
      form: ['aci','svi','sts'],
      eform: [
        { id:'nam',label:'Name',type:'tf',req:1,ops:{} },
        { id:'shc',label:'Code',type:'tf',req:1,ops:{} },
        { id:'scd',label:'Sort Code',type:'tf',req:1,ops:{} },
        { id:'swc',label:'Swift Code',type:'tf',req:1,ops:{} },
        { id:'eni',label:'Entity',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entity'} },
        { id:'lci',label:'Location',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'location' } },
        { id:'exi',label:'Extra Info',type:'jf',req:0,ops:{} },
        { id:'dtl',label:'Details',type:'ta',req:0,ops:{} },
        { id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status' } }
      ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
    formatters: {grid: {exi:fjd}}
  },

  paramactive: {
    service: {
      name: 'paramactive',
      title: 'Active Parameter',
      key: 'rid',
      num: true,
      fields: ['rid','pgn','pgc','sts','ast','stp'],
      grid: [['pgn','Group'],['pgc','Code'],['sts','Status'],['stp','Stamp']],
      bar: {pag:true,btn:['add','edit','del','view'] },
      menu: {mnu:[{t:'Group',v:'pgn'},{t:'Code',v:'pgc'}],def:'pgn' },//*
      form: ['aci','svi','sts'],
      eform: [
        { id:'nam',label:'Name',type:'tf',req:1,ops:{} },
        { id:'shc',label:'Code',type:'tf',req:1,ops:{} },
        { id:'scd',label:'Sort Code',type:'tf',req:1,ops:{} },
        { id:'swc',label:'Swift Code',type:'tf',req:1,ops:{} },
        { id:'eni',label:'Entity',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entity'} },
        { id:'lci',label:'Location',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'location' } },
        { id:'exi',label:'Extra Info',type:'jf',req:0,ops:{} },
        { id:'dtl',label:'Details',type:'ta',req:0,ops:{} },
        { id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status' } }
      ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
    formatters: {grid: {exi:fjd}}
  },

  paramhistory: {
    service: {
      name: 'paramhistory',
      title: 'Parameter Setting History',
      key: 'rid',
      num: true,
      fields: ['rid','tki','mei','sno','ssn','pgi','pgc','par','att','dvi','dvc','sti','stc','psi','psc','err','nts','dcd','rmk','exi','sts','ast','stp'],
      grid: [['tki','Trackid'],['sno','Meter'],['ssn','STS No'],['pgc','Group'],['dvc','Device'],['stc','Station'],['psc','ParamStatus'],['dcd','Created'],['sts','Status'],['stp','Stamp']],
      bar: {pag:true,btn:['add','edit','del','view'] },
      menu: {mnu:[{t:'Trackid',v:'tki'},{t:'Meter',v:'sno'},{t:'STSNo',v:'ssn'}],def:'sno' },//*
      form: ['aci','svi','sts'],
      eform: [
        { id:'nam',label:'Name',type:'tf',req:1,ops:{} },
        { id:'shc',label:'Code',type:'tf',req:1,ops:{} },
        { id:'scd',label:'Sort Code',type:'tf',req:1,ops:{} },
        { id:'swc',label:'Swift Code',type:'tf',req:1,ops:{} },
        { id:'eni',label:'Entity',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entity'} },
        { id:'lci',label:'Location',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'location' } },
        { id:'exi',label:'Extra Info',type:'jf',req:0,ops:{} },
        { id:'dtl',label:'Details',type:'ta',req:0,ops:{} },
        { id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status' } }
      ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
    formatters: {grid: {exi:fjd}}
  },

  paramsetting: {
    service:{
      name:'paramsetting',
      title:'Parameter Setting',
      key:'rid',
      num: false,
      fields: ['rid','nam', 'shc', 'dsc', 'ati','atn', 'eni', 'enm', 'eti', 'aky', 'bii', 'bin', 'bic', 'cre', 'lgo', 'exi', 'sts', 'ast', 'stp'],
      params: {nam:'Service',shc:'Code',dsc:'Description',sts:'Status'},
      grid: [['nam','Name'],['shc','Code'],['dsc','Description'],['atc','Type'],['enm','Entity'],['cre','Credits'],['sts','Status'],['stp','Stamp']],
      bar: {pag:true,btn:['add','edit','del','view','man','wiz']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
            {id:'shc',label:'Code',type:'tf',req:0,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'ati',label:'Account Type',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'accounttype'}},
            {id:'eni',label:'Entity',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entity'}},
            {id:'cre',label:'Credit',type:'nf',req:0,ops:{}},
            {id:'lgo',label:'Logo',type:'if',req:0,ops:{preview:true}},
            {id:'sts',label:'Status',type:'fa',req:1,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  paramtemplate: {
    service:{
      name:'paramtemplate',
      title:'Parameter Template',
      key:'rid',
      num: false,
      fields: ['rid','pgi', 'pgn', 'pgc', 'hds','pms', 'rds', 'att', 'dcd', 'tot', 'fln', 'res', 'exi', 'sts', 'ast', 'stp'],
      params: {pgn:'Group',pgc:'Code',hds:'Headers',pms:'Parameters',rds:'Records',att:'Attachments',dcd:'Created',tot:'Total',fln:'Filename',res:'Resource',exi:'Extrainfo',sts:'Status'},
      grid: [['pgn','Parameter Group'],['pgc','Code'],['dcd','Created'],['pms','Parameters'],['tot','Total'],['fln','Filename'],['exi','ExtraInfo'],['sts','Status'],['stp','Stamp']],
      bar: {pag:true,btn:['add','edit','del','view','man','wiz']},
      menu: {mnu:[{t:'Group',v:'pgn'},{t:'Code',v:'pgc'}],def:'pgn'},
      eform:[{id:'pgi',label:'Param Group',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'paramgroup'}},
            {id:'hds',label:'Headers',type:'jf',req:0,ops:{},off:['add','edit','delete']},
            {id:'pms',label:'Parameters',type:'jf',req:0,ops:{},off:['add','edit','delete']},
            {id:'rds',label:'Records',type:'jf',req:0,ops:{},off:['add','edit','delete']},
            {id:'att',label:'Attachments',type:'jf',req:0,ops:{},off:['add','edit','delete']},
            {id:'dcd',label:'Created',type:'df',req:0,ops:{},off:['add','edit','delete']},
            {id:'tot',label:'Total',type:'df',req:0,ops:{},off:['add','edit','delete']},
            {id:'fln',label:'File',type:'fu',req:0,ops:{preview:false,accept:xlsaccept}},
            {id:'res',label:'Resource',type:'df',req:0,ops:{},off:['add','edit','delete']},
            {id:'exi',label:'Extrainfo',type:'jf',req:0,ops:{}},
            {id:'sts',label:'Status',type:'fa',req:1,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
    formatters: {grid: {pms:fjd,res:fjd,exi:fjd}, form:{pms:fjd,res:fjd,exi:fjd}}
  },

  privilege: {
    service:{
      name:'privilege',
      title:'Privilege',
      key:'rid',
      num: false,
      fields: ['rid','nam','shc','mnm','smn','mng','lnm','ord','acl','btx','bfn','acf','ctx','sts','ast','stp'],
      params: {nam:'Service',shc:'Code',dsc:'Description',sts:'Status'},
      grid: [['nam','Privilege'],['shc','Code'],['mnm','Menu'],['smn','Submenu'],['ord','Order'],['acl','Access'],
             ['acf','Module'],['bfn','Action'],['btx','Label'],['ctx','Context'],['sts','Status'],['stp','Stamp']],
      bar: {pag:true,btn:['add','edit','del','view','man','wiz']},
      menu: {mnu:[{t:'Privilege',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,col:1,ops:{}},
            {id:'shc',label:'Code',type:'tf',req:1,col:1,ops:{}},
            {id:'mnm',label:'Menu',type:'tf',req:1,col:1,ops:{}},
            {id:'mng',label:'Group',type:'tf',req:1,col:1,ops:{}},
            {id:'smn',label:'Submenu',type:'tf',req:1,col:1,ops:{}},
            {id:'lnm',label:'Language',type:'tf',req:1,col:1,ops:{}},
            {id:'ord',label:'Order',type:'tf',req:1,col:2,ops:{}},
            {id:'acl',label:'Access',type:'ml',req:1,col:2,ops:{params:{s:'role',a:'find'},conf:{keyField:'aky'}}},
            {id:'btx',label:'Label',type:'tf',req:1,col:2,ops:{}},
            {id:'bfn',label:'Action',type:'tf',req:1,col:2,ops:{}},
            {id:'acf',label:'Module',type:'tf',req:1,col:2,ops:{}},
            {id:'ctx',label:'Context',type:'ml',req:1,col:2,ops:{params:{s:'context',a:'find'},conf:{keyField:'aky'}}},//,cp:{t:'bcs',s:'context'}
            {id:'sts',label:'Status',type:'fa',req:1,col:2,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'column',edit:'column',delete:'dialog',view:'column'}
  },

  //

  production: {
    service:{
      name:'production',
      title:'Production',
      num: true,
      pageMode: 'remote',
      params: {ast:'',bno:'',cni:'',dcd:'',ddt:'',dsc:'',exi:'',fac:'',fai:'',mac:'',mai:'',man:'',mcc:'',mmc:'',mmi:'',msc:''
              ,msi:'',mtc:'',mti:'',odi:'',odt:'',pdi:'',pmi:'',pmn:'',prc:'',pri:'',psc:'',psi:'',rid:'',rmk:'',sno:'',spc:''
              ,spi:'',ssn:'',stp:'',sts:'',suc:'',sui:''},
      fields:['rid','bno','dsc','fai','fan','fac','pri','prn','prc','sdt','edt','fsd','fed','ssd','sed','qts','tot','psi','psc','pmi','pmn','dcd','rmk','exi','sts','ast','stp'],
      //fields:['rid','rfn','dcd','aci','acn','acc','mti','mtn','mtc','ttl','dtl','top','exi','sts','ast','stp'],
      //grid: [['ast',''],['bno',''],['cni',''],['dcd',''],['ddt',''],['dsc',''],['exi',''],['fac',''],['fai',''],['mac',''],['mai',''],['man',''],['mcc',''],['mmc',''],['mmi',''],['msc',''],['msi',''],['mtc',''],['mti',''],['odi',''],['odt',''],['pdi',''],['pmi',''],['pmn',''],['prc',''],['pri',''],['psc',''],['psi',''],['rid',''],['rmk',''],['sno',''],['spc',''],['spi',''],['ssn',''],['stp',''],['sts',''],['suc',''],['sui','']],
      grid  : [['bno','Batch'],['fac','Facility'],['prc','Project'],['sdt','Start'],['edt','End'],['fsd','FAT Start'],
               ['qts','Quantities'],['tot','Total'],['psc','PStatus'],['pmn','Manager'],['sts','Status'],['stp','Stamp']],
      menu: {mnu:[{t:'Batch',v:'bno'},{t:'Manager',v:'pmn'}],def:'bno'},
      eform:[ {id:'rfn',label:'Reference',type:'rf',req:0,ops:{},off:['add']},
              {id:'dcd',label:'Date Created',type:'rf',req:0,ops:{},off:['add']},
              {id:'aci',label:'Account',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'account'}},
              {id:'mti',label:'Type',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'mobilemessagetype'}},
              {id:'ttl',label:'Title',type:'tf',req:1,ops:{}},
              {id:'dtl',label:'Message',type:'ta',req:0,ops:{}},
              {id:'top',label:'Topic',type:'tf',req:0,ops:{}},
              {id:'exi',label:'Extra Info',type:'rf',req:0,ops:{},off:['add']},
              {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}}
            ],
    },
    create: false,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  prodstate: {
    service: {
      name: 'prodstate',
      title: 'Product State',
      key: 'rid',
      num: true,
      fields: ['rid','nam','shc','dsc','cod','col','sts','ast','stp'],
      grid: [['nam','Name'],['shc','Code'],['cod','State Code'],['col','State Color'],['sts','Status'],['stp','Stamp']],
      bar: {pag:true,btn:['add','edit','del','view'] },
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam' },//*
      form: ['aci','svi','sts'],
      eform: [
        { id:'nam',label:'Name',type:'tf',req:1,ops:{} },
        { id:'shc',label:'Code',type:'tf',req:1,ops:{} },
        { id:'scd',label:'Sort Code',type:'tf',req:1,ops:{} },
        { id:'swc',label:'Swift Code',type:'tf',req:1,ops:{} },
        { id:'eni',label:'Entity',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entity'} },
        { id:'lci',label:'Location',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'location' } },
        { id:'exi',label:'Extra Info',type:'jf',req:0,ops:{} },
        { id:'dtl',label:'Details',type:'ta',req:0,ops:{} },
        { id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status' } }
      ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
    formatters: {grid: {exi:fjd}}
  },

  profile: {
    service: {
      name: 'profile',
      title: 'Profile',
      key: 'rid',
      num: true,
      fields: [
        'rid','nam','fnm','onm','snm','unm','roi','rnm','eti','ety','eni','enm','rfi','ct1','ct2','eml',
        'lvi','lvn','mct','sto','pla','ila','lst','flg','dct','lpd','lld','rmk','sts','ast','stp'],
      params: {nam:'Status Name',sty:'Type',sti:'Status Id',dsc:'Description',sts:'Status'},
      grid: [['nam','Name'],['unm','Username'],['ety','EntityType'],['rnm','Role'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'fnm',label:'Firstname',type:'tf',req:1,ops:{}},
            {id:'onm',label:'Othername',type:'rf',req:1,ops:{}},
            {id:'snm',label:'Surname',type:'rf',req:1,ops:{}},
            {id:'nam',label:'Username',type:'rf',req:1,ops:{}},
            {id:'unm',label:'Username',type:'rf',req:1,ops:{}},
            {id:'rnm',label:'Role',type:'rf',req:1,ops:{}},
            {id:'ety',label:'Entity Type',type:'rf',req:1,ops:{}},
            {id:'enm',label:'Entity',type:'rf',req:1,ops:{}},
            {id:'ct1',label:'Mobile',type:'rf',req:1,ops:{}},
            {id:'ct2',label:'Code',type:'rf',req:1,ops:{}},
            {id:'eml',label:'Email',type:'rf',req:1,ops:{}},
            {id:'lvn',label:'Log Level',type:'rf',req:1,ops:{}},
            {id:'mct',label:'Messages',type:'rf',req:1,ops:{}},
            {id:'sto',label:'Session Timeout',type:'rf',req:1,ops:{}},
            {id:'pla',label:'Plain Login',type:'rf',req:1,ops:{}},
            {id:'ila',label:'Image Login',type:'rf',req:1,ops:{}},
            {id:'lst',label:'Login Status',type:'rf',req:1,ops:{}},
            {id:'flg',label:'Failed Login',type:'rf',req:1,ops:{}},
            {id:'dct',label:'Created',type:'rf',req:1,ops:{}},
            {id:'lpd',label:'Last Password',type:'rf',req:1,ops:{}},
            {id:'lld',label:'Last Login',type:'rf',req:1,ops:{}},
            {id:'rmk',label:'Remarks',type:'rf',req:1,ops:{}},
            {id:'ast',label:'Status',type:'rf',req:1,ops:{}},
            {id:'stp',label:'Last Updated',type:'rf',req:1,ops:{}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  // provider: {
  //   service: {
  //     name: 'provider',
  //     title: 'Service Providers',
  //     key: 'rid',
  //     num: true,
  //     fields: ['rid','nam', 'shc', 'dsc', 'pls', 'sts', 'ast', 'stp'],
  //     params: {nam:'Context',shc:'Code',dsc:'Description',pls:'Privileges',sts:'Status'},
  //     grid: [['nam','Name'],['shc','Code'],['dsc','Description'],['pls','Privileges'],['sts','Status'],['stp','Stamp']],
	// 	  bar: {pag:true,btn:['add','edit','del','view']},
  //     menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
  //     eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
  //           {id:'shc',label:'Code',type:'tf',req:0,ops:{}},
  //           {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
  //           {id:'pls',label:'Privileges',type:'ta',req:1,ops:{}},
  //           {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
  //         ],
  //   },
  //   create:true,
  //   actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
  //   panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  // },

  // register: {
  //   //fieldTypes: {tf:'',ta:'',nf:'',df:'',cf:'',rf:'',cb:'',fa:'',rb:'',if:'',ff:''},
  //   service:{
  //     name:'register',
  //     title:'Register',
  //     key:'rid',
  //     num: false,
  //     fields:['rid','rfn','tri','rqi','dcd','tdt','soi','aci','acn','acc',
  //     'tci','tcn','tcc','csi','csn','cco','mbn','gdi','idn','ctn',
  //     'chi','chn','chc','api','apn','apc','apt','dcd','xti','xtn','xtc',
  //     'cui','cur','cuc','amt','dsc','req','att','xsi','xsn','xsc',
  //     'rsc','rst','res','exi','dtl','sts','stp'],
  //     grid  : [['dcd','Date'],['rfn','Reference'],['csn','Customer'],['mbn','Mobile'],['tcn','Center'],['xtc','Type'],['xsc','Test Status'],['dtl','Detail'],['rst','Status']],
  //     bar: {pag:true,btn:['add','edit','del','view','man','wiz']},
  //     menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
  //     //			paramsadd:':rfn,:tdt,:aai,:xti,:tci,:csi,:dsc,:xsi,:rst,:exi,:dtl',

  //     eform:[{id:'aai',label:'Service',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'apiaccess'},off:['edit','view']},
  //             {id:'apn',label:'Service',type:'rf',req:0,ops:{},off:['add']},
  //             {id:'xti',label:'Contribution Type',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'testingtype'},off:['edit','view']},
  //             {id:'xtn',label:'Contribution Type',type:'rf',req:0,ops:{},off:['add']},
  //             {id:'tdt',label:'Contribution Date',type:'df',req:0,ops:{},off:['edit']},
  //             {id:'dsc',label:'Description',type:'tf',req:1,ops:{},off:['edit']},
  //             {id:'csi',label:'Customer',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'customer'},off:['edit']},
  //             {id:'tci',label:'Center',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'testingcenter'}},
  //             {id:'xsi',label:'Status',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'testingstatus'}},
  //             {id:'rst',label:'Result',type:'tf',req:1,ops:{}},
  //             {id:'rfn',label:'Reference',type:'rf',req:0,ops:{},off:['add','edit']},
  //             {id:'dtl',label:'Notes',type:'ta',req:0,ops:{},off:['edit']},
  //             {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'},off:['add']},
  //           ],
  //   },
  //   create:true,
  //   //actions: {add:'Add',view:'View'},
  //   //panels: {add:'simple',view:'simple'}
  //   actions: {add:'Add',edit:'Edit',delete:'Delete',view:'View'},
  //   panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  // },

  reportheader: {
    service: {
      name: 'reportheader',
      title: 'Report Header',
      key: 'rid',
      num: true,
      fields: ['rid','nam','shc','dsc','rpi','rpn','sts','ast','stp'],
      params: {nam:'Context',shc:'Code',dsc:'Description',rpi:'Report',sts:'Status'},
      grid: [['nam','Name'],['shc','Code'],['dsc','Description'],['rpn','Report'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
            {id:'shc',label:'Code',type:'tf',req:0,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'rpi',label:'Report',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'report'}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  reportsettings: {
    service: {
      name: 'reportsettings',
      title: 'Report Settings',
      key: 'rid',
      num: true,
      fields: ['rid','nam','shc','dsc','rpi','rpn','sts','ast','stp'],
      params: {nam:'Context',shc:'Code',dsc:'Description',rpi:'Report',sts:'Status'},
      grid: [['nam','Name'],['shc','Code'],['dsc','Description'],['rpn','Report'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
            {id:'shc',label:'Code',type:'tf',req:0,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'rpi',label:'Report',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'report'}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  reportsummary: {
    service: {
      name: 'reportsummary',
      title: 'Report Summary',
      key: 'rid',
      num: true,
      fields: ['rid','nam','shc','dsc','rpi','rpn','sts','ast','stp'],
      params: {nam:'Context',shc:'Code',dsc:'Description',rpi:'Report',sts:'Status'},
      grid: [['nam','Name'],['shc','Code'],['dsc','Description'],['rpn','Report'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
            {id:'shc',label:'Code',type:'tf',req:0,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'rpi',label:'Report',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'report'}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  // request: {
  //   service:{
  //     name:'request',
  //     title:'Request',
  //     num: true,
  //     fields:['rid','rqi','dcd','cli','cln','clc','rto','rbo','rin','cco','chn',
  //             'rsi','rsn','rsc','aui','aun','auc','rco','rtx','exi','sts','ast','stp'],
  //     grid  : [['dcd','Date'],['rqi','RequestID'],['cln','Client'],['rto','Token'],['rbo','Body'],['rin','Info'],['cco','Code'],
  //              ['chn','Channel'],['rsc','State'],['aun','Auth'],['rtx','Response'],['exi','Extra'],['sts','Status'],['stp','Stamp']],
  //     menu: {mnu:[{t:'Date',v:'sdt'},{t:'Code',v:'cco'}],def:'cco'},
  //     eform:[
  //       //{id:'aci',label:'Status Code',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'requeststatus'},off:['edit']},
  //       //{id:'sco',label:'Status Code',type:'tf',req:1,ops:{} },
  //       //{id:'stx',label:'Status Text',type:'ta',req:0,ops:{} },
  //       //{id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},     
  //     ],
  //   },
  //   create:false,
  //   panels: { 
  //     //edit:'simple',view:'result',close:'dialog',check:'dialog',print:'result'
  //     view:'result',close:'dialog',check:'dialog',print:'result'
  //   },
  //   formatters: {
  //     grid: {rbo:fjd,rin:fjd,exi:fjd}
  //   }
  // },

  role: {
    service:{
      name:'role',
      title:'Role',
      key:'rid',
      num: true,
      fields: ['rid','nam', 'dsc', 'sto','aky', 'acl', 'eti', 'ety', 'sts', 'ast', 'stp'],
      params: {nam:'Service',dsc:'Description',sto:'Timeout',sts:'Status'},
      grid: [['nam','Name'],['dsc','Description'],['sto','Session Timeout'],['aky','Access Key'],['acl','Access Level'],['ety','EntityType'],['sts','Status'],['stp','Stamp']],
      bar: {pag:true,btn:['add','edit','del','view','man','wiz']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Description',v:'dsc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'eti',label:'Entity Type',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entitytype'}},
            {id:'aky',label:'Access Key',type:'mf',req:0,ops:{params:{s:'role',a:'access'},conf:{recField:'rid'}}},
            {id:'sto',label:'Timeout',type:'nf',req:0,ops:{}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  // service: {
  //   service: {
  //     name: 'service',
  //     title: 'Service',
  //     key: 'rid',
  //     num: true,
  //     fields: ['rid','nam','sti','stn','apn','acl','dsc','ipm','opm','exo','exi','sts','ast','stp'],
  //     grid: [['nam','Service'],['stn','Type'],['apn','Application'],['dsc','Description'],['sts','Status'],['stp','Stamp']],
  //     bar: { pag: true, btn: ['add', 'edit', 'del', 'view'] },
  //     menu: {mnu:[{t:'Service',v:'nam'},{t:'Application',v:'apn'}],def:'nam'},
  //     form: ['aci', 'svi', 'sts'],
  //     eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
  //             {id:'sti',label:'Type',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'servicetype'}},
  //             {id:'apn',label:'Application',type:'tf',req:1,ops:{}},
  //             {id:'acl',label:'Log Access',type:'ml',req:1,ops:{params:{s:'servicelog',a:'find'},conf:{keyField:'aky'}}},
  //             {id:'dsc',label:'Description',type:'ta',req:0,ops:{minRows:2,maxRows:3}},
  //             {id:'ipm',label:'Input Params',type:'jf',req:0,ops:{rows:2,rowsMax:3}},
  //             {id:'opm',label:'Output Params',type:'jf',req:0,ops:{rows:2,rowsMax:3}},
  //             {id:'exo',label:'Extra Options',type:'jf',req:0,ops:{rows:2,rowsMax:3}},
  //             {id:'exi',label:'Extra Info',type:'jf',req:0,ops:{rows:2,rowsMax:3}},
  //             {id:'sts',label:'Status',type:'fa',req:1,ops:{},cp:{t:'lcs',s:'status'}},
  //           ],
  //   },
  //   create:false,
  //   actions: {add:'Add',edit:'Edit',delete:'Delete',view:'View'},
  //   panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  // },

  // serviceconfig: {
  //   service: {
  //     name: 'serviceconfig',
  //     title: 'Service Config',
  //     key: 'rid',
  //     num: true,
  //     fields: ['rid','svi','sti','svn','ofs','app','fun','gts','ope','pro','tid','mid','ext','exi','dtl','sts','ast','stp'],
  //     grid: [['svn','Service'],['ofs','OFS'],['app','Application'],['fun','Function'],['ope','Operation'],
  //            ['pro','Process'],['ext','Extra'],['exi','Extra Info'],['sts','Stattus'],['stp','Stamp']],
  //     bar: { pag: true, btn: ['add', 'edit', 'del', 'view'] },
  //     menu: {mnu:[{t:'Name',v:'nam'},{t:'svn',v:'Service'}],def:'nam'},
  //     form: ['aci', 'svi', 'sts'],
  //     //':svi,:onm,:app,:fun,:gts,:ope,:pro,:tid,:mid,:ext,:exi,:dtl,:sts'
  //     eform:[{id:'svi',label:'Service',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'service'}},
  //             {id:'ofs',label:'Ofs Name',type:'tf',req:1,ops:{}},
  //             {id:'app',label:'Application',type:'tf',req:0,ops:{}},
  //             {id:'fun',label:'Ofs Function',type:'tf',req:0,ops:{}},
  //             {id:'gts',label:'Gts Control',type:'tf',req:0,ops:{}},
  //             {id:'ope',label:'Operation',type:'tf',req:0,ops:{}},
  //             {id:'pro',label:'Process',type:'tf',req:0,ops:{}},
  //             {id:'tid',label:'Transaction ID',type:'tf',req:0,ops:{}},
  //             {id:'mid',label:'Message ID',type:'tf',req:0,ops:{}},
  //             {id:'ext',label:'Extra Field',type:'tf',req:0,ops:{}},
  //             {id:'exi',label:'Extra Info',type:'jf',req:0,ops:{}},
  //             {id:'dtl',label:'Details',type:'ta',req:0,ops:{}},
  //             {id:'sts',label:'Status',type:'fa',req:1,ops:{},cp:{t:'lcs',s:'status'}},
  //           ],
  //   },
  //   create:false,
  //   actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
  //   panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
  //   formatters: { grid: {exi:fjd}}
  // },

  // servicecontrol: {
  //   service: {
  //     name: 'servicecontrol',
  //     title: 'Service Control',
  //     key: 'rid',
  //     num: true,
  //     fields: ['rid','nam','sti','stn','svi','svn','acl','dsc','sts','ast','stp'],
  //     grid: [['nam','Name'],['stn','Type'],['svn','Service'],['dsc','Description'],['sts','Status'],['stp','Stamp']],
  //     bar: { pag: true, btn: ['add', 'edit', 'del', 'view'] },
  //     menu: {mnu:[{t:'Name',v:'nam'},{t:'svn',v:'Service'}],def:'nam'},
  //     form: ['aci', 'svi', 'sts'],
  //     eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
  //             {id:'sti',label:'Type',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'servicetype'},chs:['svi']},
  //             {id:'svi',label:'Service',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'service'},pns:['sti']},
  //             {id:'acl',label:'Access Level',type:'ml',req:1,ops:{params:{s:'client',a:'find'},conf:{keyField:'aky'}}},
  //             {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
  //             {id:'sts',label:'Status',type:'fa',req:1,ops:{},cp:{t:'lcs',s:'status'}},
  //           ],
  //   },
  //   create:true,
  //   actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
  //   panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  // },

  // servicecriteria: {
  //   service: {
  //     name: 'servicecriteria',
  //     title: 'Service Criteria',
  //     key: 'rid',
  //     num: true,
  //     fields: ['rid','sci','svi','onm','fid','fnm','ope','sor','val','sts','ast','stp'],
  //     grid: [['onm','Ofs Name'],['fid','Field ID'],['fnm','Field Name'],['ope','Operand'],['sor','Sort'],['val','Default Value'],['sts','Status'],['stp','Stamp']],
  //     bar: { pag: true, btn: ['add', 'edit', 'del', 'view'] },
  //     menu: {mnu:[{t:'Name',v:'nam'},{t:'svn',v:'Service'}],def:'nam'},
  //     form: ['aci', 'svi', 'sts'],
  //     eform:[{id:'svi',label:'Service ID',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'service'}},
  //             {id:'fid',label:'Field ID',type:'tf',req:1,ops:{}},
  //             {id:'fnm',label:'Field Name',type:'tf',req:1,ops:{}},
  //             {id:'ope',label:'Operand',type:'tf',req:1,ops:{}},
  //             {id:'sor',label:'Sort',type:'tf',req:0,ops:{}},
  //             {id:'val',label:'Default Value',type:'tf',req:0,ops:{}},
  //             {id:'sts',label:'Status',type:'fa',req:1,ops:{},cp:{t:'lcs',s:'status'}},
  //           ],
  //   },
  //   create:false,
  //   actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
  //   panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  // },

  // servicefield: {
  //   service: {
  //     name: 'servicefield',
  //     title: 'Service Field',
  //     key: 'rid',
  //     num: true,
  //     fields: ['rid','sci','svi','onm','fid','fnm','mvn','svn','val','sts','ast','stp'],
  //     grid: [['onm','Ofs Name'],['fid','Field ID'],['fnm','Field Name'],['mvn','MVN'],['svn','SVN'],['val','Default Value'],['sts','Status'],['stp','Stamp']],
  //     bar: { pag: true, btn: ['add', 'edit', 'del', 'view'] },
  //     menu: {mnu:[{t:'Name',v:'nam'},{t:'svn',v:'Service'}],def:'nam'},
  //     form: ['aci', 'svi', 'sts'],
  //     eform:[{id:'svi',label:'Service ID',type:'fa',req:1,ops:{}, cp:{t:'bcs',s:'service'}},
  //             {id:'fid',label:'Field ID',type:'tf',req:1,ops:{}},
  //             {id:'fnm',label:'Field Name',type:'tf',req:1,ops:{}},
  //             {id:'mvn',label:'Operand',type:'tf',req:1,ops:{}},
  //             {id:'svn',label:'Sort',type:'tf',req:0,ops:{}},
  //             {id:'val',label:'Default Value',type:'tf',req:0,ops:{}},
  //             {id:'sts',label:'Status',type:'fa',req:1,ops:{},cp:{t:'lcs',s:'status'}},
  //           ],
  //   },
  //   create:false,
  //   actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
  //   panels: {add:'simple',delete:'dialog',view:'simple'}
  // },

  // servicelog: {
  //   service: {
  //     name: 'servicelog',
  //     title: 'Service Logging',
  //     key: 'rid',
  //     num: true,
  //     fields: ['rid','nam','shc','aky','acl','dsc','sts','ast','stp'],
  //     grid: [['nam','Name'],['shc','Short Code'],['acl','Access Level'],['dsc','Description'],['sts','Status'],['stp','Stamp']],
  //     bar: { pag: true, btn: ['add', 'edit', 'del', 'view'] },
  //     menu: {mnu:[{t:'Name',v:'nam'},{t:'svn',v:'Service'}],def:'nam'},
  //     form: ['aci', 'svi', 'sts'],
  //     eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
  //             {id:'shc',label:'Short Code',type:'tf',req:1,ops:{}},
  //             {id:'aky',label:'Access Key',type:'mf',req:1,ops:{params:{s:'servicelog',a:'access'},conf:{recField:'rid'}}},
  //             {id:'dsc',label:'Description',type:'tf',req:0,ops:{}},
  //             {id:'sts',label:'Status',type:'fa',req:1,ops:{},cp:{t:'lcs',s:'status'}},
  //           ],
  //   },
  //   create:true,
  //   actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
  //   panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  // },

  // serviceparam: {
  //   service: {
  //     name: 'serviceparam',
  //     title: 'Service Parameters',
  //     key: 'rid',
  //     num: true,
  //     fields: ['rid','nam','svi','svn','sti','fid','val','dsc','sts','ast','stp'],
  //     grid: [['nam','Name'],['svn','Service Name'],['fid','Field ID'],['dsc','Description'],['sts','Status'],['stp','Stamp']],
  //     bar: { pag: true, btn: ['add', 'edit', 'del', 'view'] },
  //     menu: {mnu:[{t:'Name',v:'nam'},{t:'svn',v:'Service'}],def:'nam'},
  //     form: ['aci', 'svi', 'sts'],
  //     eform:[{id:'fnm',label:'Name',type:'tf',req:1,ops:{}},
  //             {id:'svi',label:'Service ID',type:'fa',req:1,ops:{}, cp:{t:'bcs',s:'service'}},
  //             {id:'fid',label:'Field ID',type:'tf',req:1,ops:{}},
  //             {id:'val',label:'Default Value',type:'tf',req:0,ops:{}},
  //             {id:'dsc',label:'Description',type:'tf',req:0,ops:{}},
  //             {id:'sts',label:'Status',type:'fa',req:1,ops:{},cp:{t:'lcs',s:'status'}},
  //           ],
  //   },
  //   create:false,
  //   actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
  //   panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  // },

  setting: {
    service: {
      name: 'setting',
      title: 'Setting',
      key: 'rid',
      num: true,
      fields: ['rid','nam','shc','typ','val','dtl','sts','ast','stp'],
      params: {nam:'Setting',shc:'Code',typ:'Type',val:'Value',dtl:'Details',sts:'Status'},
      grid: [['nam','Setting'],['shc','Code'],['typ','Type'],['val','Value'],['dtl','Details'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view']},
      menu: {mnu:[{t:'Setting',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Setting',type:'tf',req:1,ops:{}},
            {id:'shc',label:'Code',type:'tf',req:1,ops:{}},
            {id:'typ',label:'Type',type:'tf',req:1,ops:{}},
            {id:'val',label:'Value',type:'tf',req:1,ops:{}},
            {id:'dtl',label:'Detail',type:'ta',req:0,ops:{}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  stationorder: {
    service: {
      name: 'stationorder',
      title: 'Station Order',
      key: 'rid',
      num: true,
      fields: ['rid','sti','stc','ord','sts','ast','stp'],
      grid: [['stc','Station'],['ord','Order'],['sts','Status'],['stp','Stamp']],
      bar: {pag:true,btn:['add','edit','del','view'] },
      menu: {mnu:[{t:'Station',v:'stc'},{t:'Order',v:'ord'}],def:'stc' },//*
      form: ['aci','svi','sts'],
      eform: [
        { id:'nam',label:'Name',type:'tf',req:1,ops:{} },
        { id:'shc',label:'Code',type:'tf',req:1,ops:{} },
        { id:'scd',label:'Sort Code',type:'tf',req:1,ops:{} },
        { id:'swc',label:'Swift Code',type:'tf',req:1,ops:{} },
        { id:'eni',label:'Entity',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entity'} },
        { id:'lci',label:'Location',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'location' } },
        { id:'exi',label:'Extra Info',type:'jf',req:0,ops:{} },
        { id:'dtl',label:'Details',type:'ta',req:0,ops:{} },
        { id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status' } }
      ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
    formatters: {grid: {exi:fjd}}
  },

  // settlement: {
  //   service: {
  //     name: 'settlement',
  //     title: 'Settlement',
  //     key: 'rid',
  //     num: true,
  //     fields: ['rid','dcd','rqi','rfn','txd','na1','na2','amt','dba','cda','ssi','ssn','ssc','sts','ast','stp'],
  //     params: {nam:'Setting',shc:'Code',typ:'Type',val:'Value',dtl:'Details',sts:'Status'},
  //     grid: [['txd','Transaction Date'],['na1','Naration 1'],['na2','Naration 2'],['amt','Amount'],['dba','Debit Account'],
  //            ['cda','Credit Account'],['ssn','Settlement Status']],
	// 	  bar: {pag:true,btn:['add','edit','del','view']},
  //     menu: {mnu:[{t:'Setting',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
  //     eform:[{id:'dcd',label:'Date Created',type:'tf',req:0,col:1,ops:{}},
  //           {id:'rqi',label:'Request ID',type:'tf',req:0,col:1,ops:{}},
  //           {id:'rfn',label:'Reference',type:'tf',req:0,col:1,ops:{}},
  //           {id:'txd',label:'Transaction Date',type:'tf',req:0,col:1,ops:{}},
  //           {id:'na1',label:'Naration 1',type:'tf',req:0,col:1,ops:{}},
  //           {id:'na2',label:'Naration 2',type:'tf',req:0,col:1,ops:{}},
  //           {id:'amt',label:'Amount',type:'tf',req:0,col:2,ops:{}},
  //           {id:'dba',label:'Debit Account',type:'tf',req:0,col:2,ops:{}},
  //           {id:'cda',label:'Credit Account',type:'tf',req:0,col:2,ops:{}},
  //           {id:'ssi',label:'Settlement Status',type:'fa',req:0,col:2,ops:{},cp:{t:'bcs',s:'settlementstatus'}},
  //           {id:'ssc',label:'Settlement Status Code',type:'tf',req:0,col:2,ops:{}},
  //           {id:'sts',label:'Status',type:'fa',req:0,col:2,ops:{},cp:{t:'lcs',s:'status'}}
  //         ],
  //   },
  //   create:false,
  //   actions: {del:'Delete',delete:'Delete',view:'View'},
  //   panels: {delete:'dialog',view:'simple'}
  // },

  status: {
    service: {
      name: 'status',
      title: 'Status',
      key: 'rid',
      num: true,
      fields: ['rid','stn','sty','sti','dsc','sts','ast','stp'],
      params: {nam:'Status Name',sty:'Type',sti:'Status Id',dsc:'Description',sts:'Status'},
      grid: [['nam','Name'],['sty','Type'],['sti','Status ID'],['dsc','Description'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
            {id:'sty',label:'Type',type:'tf',req:1,ops:{}},
            {id:'sti',label:'Status ID',type:'tf',req:1,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  // subscriber: {
  //   service:{
  //     name:'subscriber',
  //     title:'Mobile Subscribers',
  //     key: 'rid',
  //     num: true,
  //     fields:['rid','uco','nam','mbn','dcd','msi','coi','coy','coc','bal','cui','cuy','cuc','iti','itn',
  //             'itc','idn','ide','sti','stn','stc','ctn','knm','kre','kco','exi','sts','ast','stp'],
  //     grid: [['nam','Name'],['mbn','Mobile'],['coy','Country'],['itn','ID Type'],['idn','ID No']
  //           ,['cuc','Currency'],['bal','Balance'],['stn','Status'],['dcd','Created'],['stp','Stamp']],
  //     menu: {mnu:[{t:'User',v:'nam'},{t:'Mobile',v:'mbn'}],def:'mbn'},
  //     eform:[{id:'nam',label:'Name',type:'tf',req:0,ops:{}},
  //           {id:'fnm',label:'Names',type:'tf',req:1,ops:{}},
  //           {id:'lnm',label:'Surname',type:'tf',req:1,ops:{}},
  //           {id:'dob',label:'Date of Birth',type:'df',req:1,ops:{}},
  //           {id:'mbn',label:'Mobile',type:'tf',req:1,ops:{type:'tel'}},
  //           {id:'coi',label:'Country',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'country'}},
  //           {id:'cui',label:'Currency',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'currency'}},
  //           {id:'bal',label:'Balance',type:'nf',req:0,ops:{},off:['add','edit']},
  //           {id:'iti',label:'ID Type',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'idtype'}},
  //           {id:'idn',label:'ID No',type:'tf',req:0,ops:{} },
  //           {id:'sti',label:'User Status',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'subscriberstatus'}},
  //           {id:'ctn',label:'Contact',type:'tf',req:0,ops:{} },
  //           //{id:'knm',label:'Kin Name',type:'tf',req:0,ops:{}},
  //           //{id:'kre',label:'Kin Relation',type:'tf',req:0,ops:{type:'eml'}},
  //           //{id:'kco',label:'Kin Contact',type:'tf',req:0,ops:{}},
  //           //{id:'exi',label:'Extra Info',type:'ta',req:0,ops:{}},
  //           {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'},off:['add']},
  //         ],
  //   },
  //   create:true,
  //   actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
  //   panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  // },

  // transaction: {
  //   service:{
  //     name:'transaction',
  //     title:'Transactions',
  //     num: true,
  //     fields:['rid','rfn','tri','rqi','dcd','tdt','cli','cln','clc','aga','tco','usi','usn','tid','tac','tyc','xti','xtn','xtc',
  //             'bri','brn','brc','dba','dbr','cda','cdr','cur','amt','dtl','rsn','rst','req','res','rmk','exi','sts','stp'],
  //     grid  : [['dcd','Date'],['rfn','Reference'],['rqi','RequestID'],['cln','Client'],['xtc','Type'],['brn','RCB'],['cur','Currency'],['amt','Amount'],['dtl','Detail'],['rst','Status']],
  //     menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
  //     eform:[
  //           {id:'rfn',label:'Reference',type:'rf',col:1,req:0,ops:{}},
  //           {id:'tri',label:'Tracking Id',type:'rf',col:1,req:0,ops:{}},
  //           {id:'rqi',label:'Request Id',type:'rf',col:1,req:0,ops:{}},
  //           {id:'tdt',label:'Transaction Date',type:'rf',col:1,req:0,ops:{}},
  //           {id:'cln',label:'Client Name',type:'rf',col:1,req:0,ops:{}},
  //           {id:'clc',label:'Client Code',type:'rf',col:1,req:0,ops:{}},
  //           {id:'aga',label:'CB Account',type:'rf',col:1,req:0,ops:{}},
  //           {id:'tco',label:'Teller Code',type:'rf',col:1,req:0,ops:{}},
  //           {id:'usn',label:'User Name',type:'rf',col:1,req:0,ops:{}},
  //           {id:'tyc',label:'Type Code',type:'rf',col:1,req:0,ops:{}},
  //           {id:'xtn',label:'Type Name',type:'rf',col:1,req:0,ops:{}},
  //           {id:'brn',label:'Branch Name',type:'rf',col:1,req:0,ops:{}},
  //           {id:'brc',label:'Branch Code',type:'rf',col:2,req:0,ops:{}},
  //           {id:'dba',label:'Debit Account',type:'rf',col:2,req:0,ops:{}},
  //           {id:'dbr',label:'Debit Reference',type:'rf',col:2,req:0,ops:{}},
  //           {id:'cda',label:'Credit Account',type:'rf',col:2,req:0,ops:{}},
  //           {id:'cdr',label:'Credit Reference',type:'rf',col:2,req:0,ops:{}},
  //           {id:'amt',label:'Amount',type:'rf',col:2,req:0,ops:{}},
  //           {id:'dtl',label:'Payment Detail',type:'rf',col:2,req:0,ops:{}},
  //           {id:'rst',label:'Status Text',type:'rf',col:2,req:0,ops:{}},
  //           {id:'req',label:'Request',type:'jf',col:2,req:0,ops:{}},
  //           {id:'res',label:'Response',type:'jf',col:2,req:0,ops:{}},
  //          {id:'exi',label:'Extrainfo',type:'jf',col:2,req:0,ops:{}},
  //            {id:'stp',label:'Stamp',type:'rf',col:2,req:0,ops:{}}
  //     ],
  //     // eform:[{id:'dcd',label:'Request Date',type:'rf',req:0,ops:{}},
  //     //       {id:'urf',label:'User Contact',type:'rf',req:0,ops:{}},
  //     //       {id:'svc',label:'Service',type:'rf',req:0,ops:{}},
  //     //       {id:'tri',label:'Tracking ID',type:'rf',req:0,ops:{}},
  //     //       {id:'rfi',label:'Reference',type:'rf',req:0,ops:{}},
  //     //       {id:'src',label:'Source Reference',type:'rf',req:0,ops:{}},
  //     //       {id:'stn',label:'Status',type:'rf',req:0,ops:{}},
  //     //       {id:'stp',label:'Last Updated',type:'rf',req:0,ops:{}},
  //     //     ],
  //   },
  //   create:false,
  //   panels: { 
  //     view:'column',close:'dialog',check:'dialog',print:'result'
  //   },
  //   formatters: {grid: {req:fjd,res:fjd,exi:fjd}}
  // },

  // transactiontype: {
  //   service: {
  //     name: 'transactiontype',
  //     title: 'Transaction Type',
  //     key: 'rid',
  //     num: true,
  //     fields: ['rid','nam', 'shc', 'dsc', 'dba', 'cda', 'sts', 'ast', 'stp'],
  //     params: {nam:'Context',shc:'Code',dsc:'Description',dba:'Debi Account',cda:'Credit Account',sts:'Status'},
  //     grid: [['nam','Name'],['shc','Code'],['dsc','Description'],['dba','Debit Account'],['cda','Credit Account'],['sts','Status'],['stp','Stamp']],
	// 	  bar: {pag:true,btn:['add','edit','del','view']},
  //     menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
  //     eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
  //           {id:'shc',label:'Code',type:'tf',req:1,ops:{}},
  //           {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
  //           {id:'dba',label:'Debit Account',type:'tf',req:0,ops:{pattern:'[0-9]{16}'}},
  //           {id:'cda',label:'Credit Account',type:'tf',req:0,ops:{pattern:'[0-9]{16}'}},
  //           {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
  //         ],
  //   },
  //   create:true,
  //   actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
  //   panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  // },

  uicontent: {
    service: {
      name: 'uicontent',
      title: 'UI Content',
      key: 'rid',
      num: true,
      fields: ['rid','eni','enn','enc','rfi','dci','dcn','dcc','pgi','pgn','pgc','sci','scn','scc',
               'dvi','dvn','dvc','lgi','lgn','lgc','msg','dsc','amk','sts','ast','stp'],
      params: {nam:'Context',shc:'Code',dsc:'Description',dba:'Debi Account',cda:'Credit Account',sts:'Status'},
      grid: [['enn','Entity'],['rfi','Refid'],['dcn','Document'],['pgn','Page'],['scn','Section'],['dvn','Device'],
             ['lgn','Language'],['msg','Message'],['dsc','Description'],['amk','Access Mask'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view']},
      menu: {mnu:[{t:'Entity',v:'enn'},{t:'Document',v:'dcc'}],def:'enn'},
      eform:[{id:'eni',label:'Entity',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entity'}},
            {id:'dci',label:'Document',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'uidoc'}},
            {id:'pgi',label:'Page',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'uipage'}},
            {id:'sci',label:'Section',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'uisection'}},
            {id:'dvi',label:'Device',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'uidevice'}},
            {id:'lgi',label:'Language',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'uilang'}},
            {id:'msg',label:'Message',type:'ta',req:1,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'amk',label:'Access Mask',type:'nf',req:0,ops:{pattern:'[0-9]+'}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  user: {
    service:{
      name:'user',
      title:'User',
      fields:['rid','fnm','onm','snm','nam','unm','roi','rnm','eti','ety','eni','enm',
              'pla','ila','flg','ct1','ct2','eml','sid','sto','lst','dct','lld','lpd',
              'mct','bri','brn','bcd','tli','tla','lvi','lvn','rmk','sts','ast','stp'],
      grid: [['nam','Name'],['rnm','Role'],['ety','EntityType'],['enm','Entity'],['ct1','Mobile'],
             ['eml','Email'],['lvn','Loglevel'],['lst','Login'],['ast','Status'],['stp','Stamp']],
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Mobile',v:'mbn'}],def:'nam'},
      eform:[{id:'fnm',label:'Firstname',type:'tf',col:1,req:1,ops:{}},
            {id:'onm',label:'Othernames',type:'tf',col:1,req:0,ops:{}},
            {id:'snm',label:'Surname',type:'tf',col:1,req:1,ops:{}},
            {id:'unm',label:'Username',type:'tf',col:1,req:1,ops:{}},
            {id:'eti',label:'Entity Type',type:'fa',col:1,req:1,ops:{},cp:{t:'bcs',s:'entitytype'},chs:['eni']},
            {id:'eni',label:'Entity',type:'fa',col:1,req:1,ops:{},cp:{t:'bcs',s:'entity'},pns:['eti']},
            {id:'roi',label:'Role',type:'fa',col:1,req:1,ops:{},cp:{t:'bcs',s:'role'}},
            {id:'bri',label:'Branch',type:'fa',col:1,req:1,ops:{},cp:{t:'lcs',s:'branch'}},
            {id:'sts',label:'Status',type:'fa',col:1,req:1,ops:{},cp:{t:'lcs',s:'status'}},
            {id:'ct1',label:'Mobile',type:'tf',col:2,req:1,ops:{type:'tel'}},
            {id:'ct2',label:'Contact',type:'tf',col:2,req:0,ops:{}},
            {id:'eml',label:'Email',type:'tf',col:2,req:1,ops:{type:'email'}},
            {id:'pla',label:'Login Attempts',type:'nf',col:2,req:0,ops:{},off:['add']},
            {id:'sto',label:'Session Timeout',type:'nf',col:2,req:0,ops:{}},
            {id:'flg',label:'False Login',type:'nf',col:2,req:0,ops:{}},
            {id:'lst',label:'Login Status',type:'fa',col:2,req:0,ops:{},cp:{t:'bcs',s:'loginstatus'}},
            {id:'lvi',label:'Log Level',type:'fa',col:2,req:0,ops:{},cp:{t:'bcs',s:'loglevel'}},
            {id:'com',label:'Comments',type:'ta',col:2,req:0,ops:{}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View',reset:'simple'},
    panels: {add:'column',edit:'column',delete:'dialog',view:'column',close:'dialog'}
  }
}

export {serviceconfig};
