import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Debugger from '../base/debugger';
import Master from './Master';

const deb = new Debugger({qs:{log:0}});

// function content(str:any, ...params:any) {
//   return params.reduce((a:any, v:any) => a.replace(/::.*?::/, v), str);
// }

class Wizard extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    deb.log("Wizard");

    this.state = {
      message: '',
      count: 0,
      modal: true,
    };
  }

  getContent = (str:any, ...params:any) => {
    return params.reduce((a:any, v:any) => a.replace(/::.*?::/, v), str);
  }

  wizClose = (ev:any) => {
    this.setState({ modal: false });
  }

  render = () => {
    //const {mode,page,open,wizClose, theme} = this.props;
    const {service,mode,record,open,title,name,cards,list,onClose,extras,...others} = this.props;
    const {modal} = this.state;

    return (
      <Master
        deb={deb}
        // getMeta={this.getMeta}
        // fmtUI={content}title,mode,type,deb,wizClose
        service={service}
        mode={mode}
        record={record}
        title={title}
        name={name}
        cards={cards}
        list={list}
        modal={modal}
        open={open}
        wizClose={onClose}
        extras={extras}
        {...others}
      />
    )
  }
}

Wizard.propTypes = {
  service: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['add', 'process', 'wizard']), //PropTypes.string.isRequired,
  //open: PropTypes.object,
  wizClose: PropTypes.func
};

export default Wizard;
